import React, {ChangeEvent, useEffect, useState} from "react";
import {Box, Button, Card, CardContent, Divider, Paper, Typography,} from "@mui/material";
import {Add} from "@mui/icons-material";
import {IApplication, IUploadDoc, RegisterApi} from "../../../api/registerApi";
import {toast} from "react-toastify";
import useModal from "../../VehiclesPage/modals/useModal";
import DocumentModal from "../modals/DocumentModal";
import {VehiclesApi} from "../../../api/vehiclesApi";

const ApplicationCard: React.FC<{
    application: IApplication;
    openHistory: () => unknown;
}> = ({ application, openHistory }) => {
    const registerApi = new RegisterApi();
    const vehiclesApi = new VehiclesApi();
    const [files, setFiles] = useState<IUploadDoc[]>([]);
    const { isOpen, openModal, closeModal } = useModal();
    const [documentData, setDocumentData] = useState<{
        id: number;
        type: string;
        docId?:number;
    } | null>(null);

    const handleDeleteFile = (fileName: any) => {

    };


    const handleGetDocuments = async () => {
        try {
            const documents = await registerApi.getDocument(application?.id, 'Заявка');
            setFiles(documents);
        } catch (e) {
            console.log(e);
        }
    }

    const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
        try {
            if (!event.target.files || event.target.files.length === 0) {
                toast.error('Пожалуйста, выберите файл для загрузки!');
                return;
            }
            const formData = new FormData();
            formData.append("file", event.target.files[0]);
            const docId = await vehiclesApi.postVehicleDocs(formData);
            await registerApi.postDocument({
                document_title: docId,
                document_type: 'Заявка',
                document_path: docId,
                linked_object_id: application.id
            });
            toast.success('Файл успешно загружен!');
            void handleGetDocuments();
            await new Promise((resolve) => setTimeout(resolve, 1000));
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    const handleOpenDocumentModal = async (id: number, docId: number) => {
        try {
            setDocumentData({id, type: 'Заявка', docId});
            openModal('document');
        } catch (e: any) {
            toast.error(e.response?.data?.detail || "Failed to fetch document");
            console.error(e);
        }
    };

    useEffect(() => {
        if(application?.id){
            void handleGetDocuments();
        }
    }, [application?.id]);

    return (
        <Card
            sx={{
                margin: "16px auto",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                padding: 2,
            }}
        >
            <CardContent>
                {/* Two-Column Layout */}
                <Box display="flex" justifyContent="space-between" gap={4}>
                    {/* Left Side (Details) */}
                    <Paper
                        variant="outlined"
                        sx={{
                            flex: 2,
                            padding: 2,
                            borderRadius: "8px",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            {application?.title}
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="body1" gutterBottom>
                            <strong>Номер заявки: </strong>{application?.id}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <strong>Проект:</strong> {application?.project_name}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <strong>Тип:</strong> {application?.type}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <strong>Этап:</strong> {application?.stage}
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="body1" gutterBottom>
                            <strong>Статус:</strong> {application?.status}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <strong>Описание:</strong> {application?.description}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <strong>Комментарии:</strong> {application?.comments || "Нет"}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <strong>Сумма всех частей заявки:</strong> {application?.total_cost || ""}
                        </Typography>
                    </Paper>

                    {/* Right Side (Files) */}
                    <Paper
                        variant="outlined"
                        sx={{
                            flex: 1,
                            padding: 2,
                            textAlign: "center",
                            borderRadius: "8px",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                        }}
                    >
                        {/* History Button */}
                        <Button
                            onClick={openHistory}
                            variant="contained"
                            color="primary"
                            sx={{ mb: 2 }}
                        >
                            История заявки
                        </Button>

                        <Box>
                            <Typography variant="h6" gutterBottom>
                                Файлы:
                            </Typography>
                            {files.map((file, index) => (
                                    <Button
                                        onClick={() => handleOpenDocumentModal(application.id, file.id)}
                                        sx={{
                                            display: "block",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            maxWidth: '400px'
                                        }}
                                    >
                                        {file.document_title.includes("_")
                                            ? file.document_title.substring(file.document_title.indexOf("_") + 1)
                                            : file.document_title}
                                    </Button>
                            ))}
                        </Box>
                        <Button
                            color="primary"
                            component='label'
                            startIcon={<Add/>}
                            variant={"text"}
                        >
                            Добавить файл
                            <input
                                type="file"
                                hidden
                                onChange={handleFileUpload}
                            />
                        </Button>
                    </Paper>
                </Box>
            </CardContent>
            <DocumentModal
                isOpen={isOpen['document']}
                onClose={() => {
                    closeModal('document');
                    setDocumentData(null);
                }}
                documentData={documentData}
                reFetch={() => handleGetDocuments()}
            />
        </Card>
    );
};

export default ApplicationCard;
