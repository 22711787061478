import React from 'react';
import {Box, CircularProgress} from "@mui/material";
import authApi from "../../api/authApi";
import {useQuery} from "@tanstack/react-query";

const StatsPage = () => {
    const api = new authApi();

    const { data: statsUrl, isLoading, error } = useQuery({
        queryKey: ['statsUrl'],
        queryFn: () => api.getStats(),
    });

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{p: 2, height: '100vh'}}>
            <iframe src={statsUrl as string}
                    width="100%"
                    height="100%"
                    style={{ flexGrow: 1, border: 'none' }}
            />
        </Box>
    );
};

export default StatsPage;