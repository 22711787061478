import React from "react";
import { Box, Drawer, List, ListItem, ListItemText, Typography, Button, Stack } from "@mui/material";
import { INotification } from "../../../api/registerApi";
import {useNavigate} from "react-router-dom";

interface NotificationsModalProps {
    open: boolean;
    onClose: () => void;
    notifications: INotification[];
}

const NotificationsModal: React.FC<NotificationsModalProps> = ({ open, onClose, notifications }) => {
    const navigate = useNavigate();
    const handleNavigate = (link: string) => {
        const id = link.split("/").pop(); // Extracts the last segment (ID)
        if (id) {
            navigate(`/register/${id}`);
        }
    };
    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            sx={{
                "& .MuiDrawer-paper": {
                    width: 450,
                    p: 2,
                    boxShadow: 3,
                },
            }}
        >
            <Box p={2}>
                <Typography variant="h6" mb={2}>Уведомления</Typography>
                <List>
                    {notifications.length > 0 ? (
                        notifications.map((notification) => (
                            <ListItem key={notification.id} divider>
                                <Box width="100%">
                                    <Typography variant="subtitle1" fontWeight={notification.read ? "normal" : "bold"}>
                                        {notification.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {notification.message}
                                    </Typography>
                                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                                        <Typography variant="caption" color="text.disabled">
                                            {new Date(notification.created_at).toLocaleString()}
                                        </Typography>
                                        <Button
                                            onClick={() => handleNavigate(notification.link)}
                                            size="small"
                                        > 
                                            Открыть
                                        </Button>
                                    </Stack>
                                </Box>
                            </ListItem>
                        ))
                    ) : (
                        <Typography variant="body2" color="text.secondary">
                            Нет новых уведомлений
                        </Typography>
                    )}
                </List>
            </Box>
        </Drawer>
    );
};

export default NotificationsModal;