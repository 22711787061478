import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
    Box,
    FormControl,
    MenuItem,
    Select,
    TextField,
    Typography,
    Button,
    IconButton, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Autocomplete, Container,
} from '@mui/material';
import {IVehicleType, VehiclesApi} from '../../../api/vehiclesApi';
import { IVehicle } from '../../../api/authApi';
import { Field, Form, Formik, FieldArray } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import {DriversApi} from "../../../api/driversApi";
import VerticalSeparator from "../../VehiclesPage/VehicleRepair/components/VerticalSeparator";
import {IInventory, IInventoryOverview, InventoryApi, InventoryOverviewParams} from "../../../api/inventoryApi";
import {IWorker} from "../../VehiclesPage/VehicleRepair/VehicleRepairForm";
import AddIcon from "@mui/icons-material/Add";
import RestorePageIcon from '@mui/icons-material/RestorePage';
import dayjs from "dayjs";
import {MaintenanceApi} from "../../../api/maintenanceApi";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClosingMaintenanceConfirmationModal
    from "../../VehiclesPage/VehicleRepair/modals/ClosingMaintenanceConfirmationModal";
import useModal from "../../VehiclesPage/modals/useModal";
import {toast} from "react-toastify";
import RefundConfirmationModal from "../components/RefundConfirmationModal";
import {useFileDownload} from "../../../hooks/useFileDownload";

export interface IMaintenanceWorker{
    name: string;
    position: string;
}

export interface IWorkItemMaintenance {
    inventory_id: string;
    title: string;
    unit: string;
    quantity: string;
    inventory_name: string;
    is_approved: boolean;
}
export interface IElectricalSystems{
    title: string;
    condition: string;
    quantity: string;
}
export interface IMaintenanceReport {
    type: string;
    tasks: IWorkItemMaintenance[];
    customer: string;
    performer: string;
    date: string;
    commissions: IMaintenanceWorker[];
    electricalSystems: IElectricalSystems[];
    operatingMaterials: Omit<IElectricalSystems, 'quantity'>[];
    malfunction_description: string;
    operator_remark: string;
    commission_conclusion: string;
}

const MaintenanceReport: React.FC = () => {
    const { vehicleId, date, maintenanceId } = useParams();
    const { isOpen, openModal, closeModal } = useModal();
    const { downloadFile, loading, error } = useFileDownload();
    const vehicleApi = new VehiclesApi();
    const driverApi = new DriversApi();
    const inventoryApi = new InventoryApi();
    const maintenanceApi = new MaintenanceApi();
    const [vehicle, setVehicle] = useState<IVehicle>();
    const [driver, setDriver] = useState<string>('');
    const [inventory ,setInventory] = useState<IInventoryOverview[]>([]);
    const navigate = useNavigate();
    const [tempData, setTempData] = useState<any>(null);
    const [vehicleTypes, setVehicleTypes] = useState<IVehicleType[]>([])
    const [selectedRefundIndex, setSelectedRefundIndex] = useState<{ index: number; inventory_id: string | null } | null>(null);
    const [initialData, setInitialData] = useState<any>({
        type: 'to1',
        tasks: [{inventory_id: '', unit: '', title: '', quantity: '', inventory_name: ''}],
        customer: '',
        performer: '',
        date: date as string,
        commissions: [{name: '', position: ''}],
        electricalSystems: [{ title: '', condition: '', quantity: '' }],
        operatingMaterials: [{title: '', condition: ''}],
        malfunction_description: '',
        operator_remark: '',
        commission_conclusion: '',
    })

    const handleGetInventory = async (vehicleType: string, vehicleTypes: IVehicleType[]) => {
            const data = await inventoryApi.getInventoryOverview({vehicle_names: [`${vehicle?.marka as string} ${vehicle?.model as string}`]})
            setInventory(data);
    };
    const handleGetVehicleData = async () => {
        const data = await vehicleApi.getVehicleById(Number(vehicleId));
        setVehicle(data);
    };
    const handleGetDriverByVehicleId = async () => {
        const data =  await driverApi.getActiveDriverByVehicle(Number(vehicleId));
        setDriver(data[0]?.driver_name);
    }

    useEffect(() => {
        const fetchData = async () => {
            await handleGetVehicleData();
            await handleGetDriverByVehicleId();
            await handleGetVehicleTypes();
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (vehicle && vehicleTypes.length > 0) {
            void handleGetInventory(vehicle.vehicle_type, vehicleTypes);
        }
    }, [vehicle, vehicleTypes]);


    const handleSubmitValues = async (values: any) => {
        try {
            await maintenanceApi.postDate(values);
            navigate('/maintenance');
        } catch (e: any) {
            toast.error(e.response.data.msg)
        }
    }

    const handleFinishMaintenance = async (values: any) => {
        try {
            let idToUse = maintenanceId;
            if (maintenanceId === 'undefined') {
                const response = await maintenanceApi.postDate(values);
                idToUse = response.data.id; // Set the response ID
            }
            await maintenanceApi.finishMaintenance(idToUse as string);
            navigate('/maintenance');
        } catch (e:any) {
            toast.error(e.response.data.msg)
            closeModal('confirm')
        }
    };


    const handleGetMaintenanceById = async () => {
        try{
            if(maintenanceId) {
            const data = await maintenanceApi.getMaintenanceById(maintenanceId as string);
            const electricalSystems: IElectricalSystems[] = [];
            const operatingMaterials: Omit<IElectricalSystems, 'quantity'>[] = [];
            data.checks.forEach((item: any) => {
                if (item.type === "electrical_system_inspection") {
                    // Add to electricalSystems, with all required fields
                    electricalSystems.push({
                        title: item.title || '',
                        condition: item.condition || '',
                        quantity: item.quantity || ''
                    });
                } else if (item.type === "aggregates_and_consumables_inspection") {
                    // Add to operatingMaterials, excluding the quantity field
                    operatingMaterials.push({
                        title: item.title || '',
                        condition: item.condition || ''
                    });
                }
            });
            setInitialData({...data,electricalSystems, operatingMaterials});
            }
        } catch (e) {
            console.log(e);
        }
    }
    const gatherData = (values: any) => {
        const electricalSystemsChecks = values.electricalSystems?.map((system: IElectricalSystems) => ({
            type: 'electrical_system_inspection',
            title: system.title,
            condition: system.condition,
            quantity: system.quantity || 1
        }));

        const operatingMaterialsChecks = values.operatingMaterials?.map((material: Omit<IElectricalSystems, 'quantity'>) => ({
            type: 'aggregates_and_consumables_inspection',
            title: material.title,
            condition: material.condition
        }));

        const { electricalSystems, operatingMaterials, ...restValues } = values;

        return {
            ...restValues,
            vehicle_id: vehicleId,
            checks: [...electricalSystemsChecks, ...operatingMaterialsChecks],
            date: dayjs(formattedDate, 'YYYY-MM-DD').set('hour', 0).set('minute', 0).set('second', 0).format('YYYY-MM-DDTHH:mm:ss'),
        };
    };

    useEffect(() => {
        void handleGetMaintenanceById();
    }, []);
    const receivedDate = decodeURIComponent(date as string);
    const [day, month, year] = receivedDate.split('.');
    const formattedDate = `${year}-${month}-${day}`;

    const handleGetVehicleTypes = (async () => {
            const data = await vehicleApi.getVehicleTypes();
            setVehicleTypes(data);
    })

    const handleRefund = (index: number, inventory_id: string | null) => {
        setSelectedRefundIndex({ index, inventory_id });
        openModal('refundConfirmation');
    };


    const handleConfirmRefund = async (remove: (index: number) => void) => {
        if (selectedRefundIndex !== null) {
            try {
                const response = await inventoryApi.refundItem(maintenanceId as string, selectedRefundIndex.inventory_id as string, 'maintenance')
                if (response.status) {
                    remove(selectedRefundIndex.index);
                } else {
                    console.error('Failed to refund the item.');
                }
            } catch (error) {
                console.error('An error occurred during the refund process:', error);
            } finally {
                closeModal('refundConfirmation');
                setSelectedRefundIndex(null);
            }
        }
    };

    const handleDownloadDoc = () => {
        if(maintenanceId) {
            downloadFile({
                apiCall: () => maintenanceApi.downloadMaintenanceReport(Number(maintenanceId)),
            });
        }
    };

    return (
        <Container maxWidth={'lg'}  sx={{
            border: '2px solid #ccc',
        }}>
            <Formik <IMaintenanceReport>
                initialValues={initialData}
                enableReinitialize={true}
                onSubmit={(values) => {
              
                    const finalData = gatherData(values)
                    handleSubmitValues(finalData);
                }}
            >
                {({ values, handleChange }) => (
                    <>
                    <Form>
                        <>
                            <IconButton onClick={() => navigate(-1)} sx={{ mr: 2 }}>
                                <ArrowBackIcon />
                            </IconButton>
                            <Box display={'flex'} alignItems={'center'} gap={2} justifyContent={'center'}>
                                <Typography variant="h4">Талон прохождения</Typography>
                                <FormControl>
                                    <Select
                                        labelId="type-label"
                                        id="type"
                                        name="type"
                                        value={values.type}
                                        onChange={handleChange}
                                        sx={{
                                            border: 'none',
                                            '.MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                            fontWeight: 'bold',
                                            fontSize: '2.125rem',
                                        }}
                                    >
                                        <MenuItem value={'to1'} sx={{ fontSize: '2.125rem', fontWeight: 'bold' }}>
                                            ТО1
                                        </MenuItem>
                                        <MenuItem value={'to2'} sx={{ fontSize: '2.125rem', fontWeight: 'bold' }}>
                                            ТО2
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-start'} width={'100%'} pb={4}>
                                <Box>
                                    <Typography variant="body1" sx={{fontWeight: 'bold'}}>Заказчик:</Typography>
                                    <Field
                                        as={TextField}
                                        size={'small'}
                                        name="customer"
                                        value={values.customer}
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '42%',}}>
                                    <Box>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Исполнитель:</Typography>
                                        <Field
                                            as={TextField}
                                            size="small"
                                            name="performer"
                                            value={values.performer}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Дата заявления:</Typography>
                                        <Field
                                            name="date"
                                            as={TextField}
                                            type="date"
                                            size="small"
                                            value={formattedDate}
                                            InputLabelProps={{
                                                shrink: true,
                                                readOnly: true
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '24px'}}>
                                <Typography variant="body1" fontWeight={'bold'} >На производство ТО: </Typography>
                                {/* First Item */}
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '22%' }}>
                                    <Typography variant="body1" fontWeight={'bold'}>Марка:</Typography>
                                    <Field
                                        size="small"
                                        as={TextField}
                                        name="marka"
                                        value={vehicle?.marka}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Box>
                                {/* Second Item */}
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '22%' }}>
                                    <Typography variant="body1" fontWeight={'bold'}>Водитель / Оператор:</Typography>
                                    <Field
                                        size="small"
                                        as={TextField}
                                        name="driver"
                                        value={driver}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Box>
                                <VerticalSeparator/>
                                {/* Third Item */}
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '22%' }}>
                                    <Typography variant="body1" fontWeight={'bold'}>Гос. номер:</Typography>
                                    <Field
                                        size="small"
                                        as={TextField}
                                        name="gos_number"
                                        value={vehicle?.gos_number}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Box>
                                <VerticalSeparator/>
                                {/* Fourth Item */}
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '22%' }}>
                                    <Typography variant="body1" fontWeight={'bold'}>Пробег:</Typography>
                                    <Field
                                        size="small"
                                        as={TextField}
                                        name="mileage"
                                        value={vehicle?.default_tracking_type === 'Пробег' ? vehicle?.mileage : vehicle?.moto_hours}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Typography variant="body1" fontWeight={'bold'} pb={1}>Комиссия в составе: </Typography>
                            <FieldArray name="commissions">
                                {({ push, remove }) => (
                                    <div style={{marginBottom: '24px'}}>
                                        {values.commissions?.map((comm, index) => (
                                            <Box
                                                key={index}
                                                sx={{
                                                    p: 1,
                                                    display: 'flex',
                                                    gap: '16px',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <Field
                                                    size={'small'}
                                                    as={TextField}
                                                    name={`commissions[${index}].position`}
                                                    value={comm.position}
                                                    label="Должность"
                                                    onChange={handleChange}
                                                />
                                                <Field
                                                    as={TextField}
                                                    size={'small'}
                                                    label="ФИО"
                                                    name={`commissions[${index}].name`}
                                                    value={comm.name}
                                                    onChange={handleChange}
                                                />
                                                {values.commissions.length > 1 && (
                                                    <IconButton
                                                        onClick={() => remove(index)}
                                                    >
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                )}
                                            </Box>
                                        ))}
                                        <Button
                                            onClick={() => push({ position: "", name: "" })}
                                            startIcon={<AddIcon />}
                                            sx={{ mt: 2 }}
                                        >
                                            Добавить
                                        </Button>
                                    </div>
                                )}
                            </FieldArray>
                            {/* FieldArray for dynamic workItems */}
                            <Typography variant="h6" fontWeight={'bold'} pb={1}>Наименование работ: </Typography>
                            <FieldArray name="tasks">
                                {({ remove, push }) => (
                                    <>
                                        <TableContainer component={Paper} sx={{padding: '16px', marginBottom: '24px'}}>
                                            <Table aria-label="installation tasks table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>№</TableCell>
                                                        <TableCell>Наименование</TableCell>
                                                        <TableCell>ТМЗ наименование</TableCell>
                                                        <TableCell>Кол-во</TableCell>
                                                        <TableCell>Ед. измерения</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {values?.tasks?.map((item: IWorkItemMaintenance, index: number) => (
                                                        <TableRow key={index}>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell sx={{width:'400px'}}>
                                                                <Field
                                                                    name={`tasks[${index}].title`}
                                                                    as={TextField}
                                                                    size={'small'}
                                                                    fullWidth
                                                                    inputProps={{readOnly: item.is_approved}}
                                                                    label="Наименование"
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Autocomplete
                                                                    sx={{ width: '300px' }}
                                                                    size={'small'}
                                                                    disabled={item.is_approved}
                                                                    options={inventory}
                                                                    getOptionLabel={(option) => option.name}
                                                                    value={inventory.find(option => String(option.id) === String(item.inventory_id)) || null}
                                                                    onChange={(event, value) => {
                                                                        handleChange({
                                                                            target: {
                                                                                name: `tasks[${index}].inventory_id`,  // Assuming you want to store id as well
                                                                                value: value ? value.id : '',
                                                                            }
                                                                        });
                                                                        handleChange({
                                                                            target: {
                                                                                name: `tasks[${index}].unit`,  // Update unit based on the selected inventory
                                                                                value: value ? value.unit : '',
                                                                            }
                                                                        });
                                                                        handleChange({
                                                                            target: {
                                                                                name: `tasks[${index}].inventory_name`,  // Store the description as inventory_name
                                                                                value: value ? value.name : '',
                                                                            }
                                                                        });
                                                                    }}
                                                                    isOptionEqualToValue={(option, value) => String(option.id) === String(value?.id)}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            name={`tasks[${index}].inventory_id`}
                                                                            value={item.inventory_id}
                                                                        />
                                                                    )}
                                                                />
                                                            </TableCell>
                                                            <TableCell sx={{ width: '100px' }}>
                                                                <Field
                                                                    name={`tasks[${index}].quantity`}
                                                                    type={'number'}
                                                                    inputProps={{
                                                                        readOnly: item.is_approved,
                                                                    }}
                                                                    onInput={(e: any) => {
                                                                        const value = e.target.value;
                                                                        if (value === '0' || value.includes('-')) {
                                                                            e.target.value = '';
                                                                        }
                                                                    }}
                                                                    onBlur={(e: any) => {
                                                                        if (e.target.value < 1 && e.target.value !== '') {
                                                                            e.target.value = 1;
                                                                        }
                                                                    }}
                                                                    size={'small'}
                                                                    as={TextField}
                                                                />
                                                            </TableCell>
                                                            <TableCell sx={{ width: '100px' }}>
                                                                <Field
                                                                    name={`tasks[${index}].unit`}
                                                                    as={TextField}
                                                                    size={'small'}
                                                                    value={inventory.find(option => String(option.id) === String(item.inventory_id))?.unit || ''}
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.is_approved ? (
                                                                    <IconButton
                                                                        color="warning"
                                                                        onClick={() => handleRefund(index, item.inventory_id)}
                                                                    >
                                                                        <RestorePageIcon/>
                                                                    </IconButton>
                                                                ) : (
                                                                    <IconButton
                                                                        onClick={() => remove(index)}
                                                                        color="error"
                                                                    >
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                            <Box p={2}>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => push({ inventory_id: '', unit: '', quantity: '', title: '', inventory_name: '' })}
                                                >
                                                    Добавить работу
                                                </Button>
                                            </Box>
                                        </TableContainer>
                                        <RefundConfirmationModal
                                            open={isOpen['refundConfirmation']}
                                            onClose={() => closeModal('refundConfirmation')}
                                            onConfirm={() => handleConfirmRefund(remove)}
                                        />
                                    </>
                                )}
                            </FieldArray>
                            <Typography variant="h6" fontWeight={'bold'} pb={1}>Проверка системы электрооборудования:</Typography>
                            {/*FieldArray for electricalSystems*/}
                            <FieldArray
                                name="electricalSystems"
                                render={(arrayHelpers) => (
                                    <TableContainer component={Paper} sx={{padding: '16px', marginBottom: '24px'}}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>№</TableCell>
                                                    <TableCell>Наименование</TableCell>
                                                    <TableCell>Состояние исправности, изоляции, контакта, и крепления</TableCell>
                                                    <TableCell sx={{ width: '100px' }} >Кол-во</TableCell>
                                                    <TableCell />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {values.electricalSystems?.map((system, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell sx={{ padding: '8px', textAlign: 'center' }}>
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Field
                                                                as={TextField}
                                                                name={`electricalSystems[${index}].title`}
                                                                size="small"
                                                                placeholder="Наименование"
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Field
                                                                as={TextField}
                                                                name={`electricalSystems[${index}].condition`}
                                                                size="small"
                                                                placeholder="Состояние"
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={{ width: '100px' }}>
                                                            <Field
                                                                as={TextField}
                                                                name={`electricalSystems[${index}].quantity`}
                                                                size="small"
                                                                placeholder="Кол-во"
                                                                fullWidth
                                                                onInput={(e: any) => {
                                                                    const value = e.target.value;
                                                                    if (value === '0' || value.includes('-')) {
                                                                        e.target.value = '';
                                                                    }
                                                                }}
                                                                onBlur={(e: any) => {
                                                                    if (e.target.value < 1 && e.target.value !== '') {
                                                                        e.target.value = 1;
                                                                    }
                                                                }}
                                                                type="number"
                                                                InputProps={{ inputProps: { min: 1 } }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <IconButton
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                color="secondary"
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <Box p={2}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<AddIcon />}
                                                onClick={() => arrayHelpers.push({ name: '', condition: '', quantity: 1 })}
                                            >
                                                Добавить
                                            </Button>
                                        </Box>
                                    </TableContainer>
                                )}
                            />


                            {/*agregaty */}

                            <Typography variant="h6" fontWeight={'bold'} pb={1}>Проверка агрегатов и эксплутационных материалов: </Typography>
                            <FieldArray
                                name="operatingMaterials"
                                render={(arrayHelpers) => (
                                    <TableContainer component={Paper} sx={{padding: '16px', marginBottom: '24px'}}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>№</TableCell>
                                                    <TableCell>Наименование</TableCell>
                                                    <TableCell>Состояние исправности, крепления и норм параметров</TableCell>
                                                    <TableCell />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {values.operatingMaterials?.map((ops, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell sx={{ padding: '8px', textAlign: 'center' }}>
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Field
                                                                as={TextField}
                                                                name={`operatingMaterials[${index}].title`}
                                                                size="small"
                                                                placeholder="Наименование"
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Field
                                                                as={TextField}
                                                                name={`operatingMaterials[${index}].condition`}
                                                                size="small"
                                                                placeholder="Состояние"
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <IconButton
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                color="secondary"
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <Box p={2}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<AddIcon />}
                                                onClick={() => arrayHelpers.push({ name: '', condition: ''})}
                                            >
                                                Добавить
                                            </Button>
                                        </Box>
                                    </TableContainer>
                                )}
                            />
                            {/*end of arrays */}

                            <Typography variant="h6" fontWeight={'bold'} pb={1}>Описание неисправностей: </Typography>
                            <Field
                                name="malfunction_description"
                                as={TextField}
                                placeholder="Описать неисправности..."
                                multiline
                                rows={2}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <Typography variant="h6" fontWeight={'bold'} pb={1}>Замечание оператора: </Typography>
                            <Field
                                name="operator_remark"
                                as={TextField}
                                placeholder="Указать замечания..."
                                multiline
                                required
                                rows={2}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <Typography variant="h6" fontWeight={'bold'} pb={1}>Комиссия пришла к заключению: </Typography>
                            <Field
                                name="commission_conclusion"
                                as={TextField}
                                placeholder="Все исправно работает..."
                                multiline
                                required
                                rows={2}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                        </>
                        <Box display={'flex'} gap={2} justifyContent={'space-between'}>
                        <Button variant="contained" color="primary" type="submit" sx={{marginBottom: '16px'}}>
                            Сохранить и выйти
                        </Button>
                        <Button onClick={handleDownloadDoc} variant="contained" color="primary" sx={{marginBottom: '16px'}} disabled={maintenanceId === 'undefined'}>
                                Скачать документ
                        </Button>
                        <Button onClick={() => (values.commission_conclusion && values.operator_remark) ? openModal('confirm')
                            : toast.error('Пожалуйста заполните обязательные поля')}
                                variant="contained" color="primary" sx={{marginBottom: '16px'}}>
                             ТО пройдено
                        </Button>
                        </Box>
                    </Form>
                        <ClosingMaintenanceConfirmationModal
                            open={isOpen['confirm']}
                            onClose={() => closeModal('confirm')}
                            onConfirm={() => handleFinishMaintenance(gatherData(values))}
                            text={'закрыть ТО'}
                        />
                    </>
                )}
            </Formik>
        </Container>
    );
};
export default MaintenanceReport;