import React, {useEffect, useState} from 'react';
import {IDispatchEquipment, IInventoryArrival, InventoryApi, IUtmEquipments} from "../../../../api/inventoryApi";
import {
    Box,
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import styles from "../../InventoryNew.module.scss";
import dayjs from "dayjs";
import useModal from "../../../VehiclesPage/modals/useModal";
import EquipmentDispatchModal from "./components/EqipmentDispatchModal";
import {toast} from "react-toastify";
import InventoryArrivalModal from "../../components/InventoryArrivalModal";
import HistoryIcon from "@mui/icons-material/History";
import EquipmentHistory from "./components/EquipmentHistory";

const EquipmentList = () => {
    const inventoryApi = new InventoryApi();
    const [equipment, setEquipment] = useState<IUtmEquipments[]>([]);
    const { isOpen, openModal, closeModal } = useModal();
    const [selectedEquipment, setSelectedEquipment] = useState<IUtmEquipments | null>(null);
    const [selectedHistory, setSelectedHistory] = useState<IUtmEquipments | null>(null);


    const handleGetEquipmentList = async () => {
        try {
            const data = await inventoryApi.getInventoryEquipment('utm');
            const sortedData = data.sort((a, b) =>
                dayjs(b.last_arrival_date).valueOf() - dayjs(a.last_arrival_date).valueOf()
            );
            setEquipment(sortedData); 
        } catch (e) {
            console.log(e);
        } finally {

        }
    }

    const handleRowClick = (equipment: IUtmEquipments) => {
        setSelectedEquipment(equipment);
        openModal('dispatch');
    };

    const handleDispatchEquipment = async (data: IDispatchEquipment) => {
        try {
            await inventoryApi.dispatchEquipmentItem(data);
            toast.success("Оборудование успешно выдано!");
            void handleGetEquipmentList();
        } catch (e: any) {
            toast.error("Ошибка выдачи оборудования:", e);
        }
    };

    const handleSaveArrival = async (arrival: IInventoryArrival) => {
        if (arrival.id) {
            const payload = {
                id: arrival.id,
                quantity: arrival.quantity,
                note: arrival.note,
                provider: arrival.provider,
                is_common_item: false,
                group_id: 6
            };
            await inventoryApi.addInventoryArrival(payload);
        } else {
            const { id, ...newArrivalPayload } = arrival;
            await inventoryApi.addInventoryArrival({...newArrivalPayload, group_id: 6,  type: 'utm' });
        }
        await handleGetEquipmentList();
        toast.success('Оборудование успешно добавлено')
        closeModal('arrival');
    };

    useEffect(() => {
        void handleGetEquipmentList();
    }, []);

    const inventoryOptions = equipment.map((item) => ({
        id: item.id,
        code: item.code,
        name: item.name,
        last_arrival: '',
        last_dispatch: '',
        unit: item.unit,
        quantity: item.quantity,
        price: item.price,
        total_price: item.total_price,
        provider: '',
        vehicles: [],
        arrivals: [],
        dispatches: [],
        last_arrival_note: '',
        last_dispatch_note: '',
    }));

    return (
        <>
        <Box>
            {selectedHistory ?
                <EquipmentHistory equipment={selectedHistory} onBack={() => setSelectedHistory(null)}/> :
                <>
            <Button variant={'contained'} sx={{mb: 2}} onClick={()=> openModal('arrival')}>
                Добавить
            </Button>
            <TableContainer component={Paper} className={styles.tableContainer}>
                <Table>
                    <TableHead className={styles.tableHeader}>
                        <TableRow>
                            <TableCell>№</TableCell>
                            <TableCell>Номенклатура</TableCell>
                            <TableCell>Код</TableCell>
                            <TableCell>цена за ед.</TableCell>
                            <TableCell>сумма</TableCell>
                            <TableCell>Ед. изм</TableCell>
                            <TableCell>Кол-во</TableCell>
                            <TableCell>Дата прихода</TableCell>
                            <TableCell>дата выдачи</TableCell>
                            <TableCell>№ заявки</TableCell>
                            <TableCell>История</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {equipment?.map((item, idx) => (
                            <TableRow className={styles.tableRow}
                                      onClick={() => handleRowClick(item)}
                                      style={{ cursor: "pointer" }}
                            >
                                <TableCell>{idx + 1}</TableCell>
                                <TableCell>{item?.name}</TableCell>
                                <TableCell>{item?.code}</TableCell>
                                <TableCell>{item?.price}</TableCell>
                                <TableCell>{item?.total_price}</TableCell>
                                <TableCell>{item?.unit}</TableCell>
                                <TableCell>{item?.quantity}</TableCell>
                                <TableCell>{dayjs(item?.last_arrival_date).format('DD.MM.YYYY')}</TableCell>
                                <TableCell>{dayjs(item?.last_dispatch_date).format('DD.MM.YYYY')}</TableCell>
                                <TableCell>{item?.id}</TableCell>
                                <TableCell align="center" onClick={(e: any) => e.stopPropagation()}>
                                    <IconButton className={styles.historyButton} onClick={() => {setSelectedHistory(item)}}>
                                        <HistoryIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
                </>
        }
        </Box>
            <EquipmentDispatchModal
                open={isOpen["dispatch"]}
                onClose={() => closeModal("dispatch")}
                onConfirm={(data) => handleDispatchEquipment(data)}
                equipment={
                    selectedEquipment
                        ? {
                            id: selectedEquipment.id,
                            name: selectedEquipment.name,
                            remaining: selectedEquipment.quantity,
                        }
                        : null
                }
            />
            <InventoryArrivalModal
                open={isOpen['arrival']}
                onClose={() => closeModal('arrival')}
                inventoryOptions={inventoryOptions}
                onSave={handleSaveArrival}
            />
        </>
    );
};

export default EquipmentList;