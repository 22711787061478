import React, { useState } from 'react';
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    IconButton,
    TextField
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import {IInventoryOverview, InventoryApi} from "../../../api/inventoryApi";
import { useFileDownload } from "../../../hooks/useFileDownload";
import { MaintenanceApi } from "../../../api/maintenanceApi";
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import {RepairApi} from "../../../api/repairApi";
dayjs.extend(isBetween);

interface HistoryTableProps {
    historyData: IInventoryOverview;
    goBack: () => void;
    onRefreshInventory: () => void;
}

type CombinedDataType =
    | { type: 'arrival'; id: number; arrival_date: string; quantity: number; note: string }
    | { type: 'dispatch'; dispatch_date: string; quantity: number; vehicle?: { id: number; full_name: string }; note: string | null; status: string; maintenance_id: number; repair_work_item_id: number };

const HistoryTable: React.FC<HistoryTableProps> = ({ historyData, goBack, onRefreshInventory }) => {
    const { downloadFile } = useFileDownload();
    const maintenanceApi = new MaintenanceApi();
    const repairApi = new RepairApi();
    const inventoryApi = new InventoryApi();
    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(null);
    const [editingNoteId, setEditingNoteId] = useState<number | null>(null);
    const [noteText, setNoteText] = useState<string>('');

    const handleDownloadMaintenanceReport = async (maintenance_id: number) => {
        try {
            await downloadFile({
                apiCall: () => maintenanceApi.downloadMaintenanceReport(maintenance_id),
            });
        } catch (e) {
            console.log(e);
        }
    };

    const handleDownloadInstallationReport = async (repairId: number) => {
        try {
            await downloadFile({
                apiCall: () => repairApi.downloadInstallationRepairDoc(String(repairId)),
            });
        } catch (e) {
            console.log(e);
        }
    };

    const handleEditNote = (arrivalId: number, currentNote: string) => {
        setEditingNoteId(arrivalId);
        setNoteText(currentNote);
    };

    const handleSaveNote = async (arrivalId: number) => {
        await inventoryApi.updateInventoryArrival({id: arrivalId, note: noteText})
        await onRefreshInventory();
        setEditingNoteId(null);
        setNoteText('');
    };

    const combinedData: CombinedDataType[] = [
        ...historyData.arrivals.map(arrival => ({ ...arrival, type: 'arrival' as const })),
        ...historyData.dispatches.map(dispatch => ({ ...dispatch, type: 'dispatch' as const })),
    ].sort((a, b) => {
        const dateA = a.type === 'arrival' ? dayjs(a.arrival_date) : dayjs(a.dispatch_date);
        const dateB = b.type === 'arrival' ? dayjs(b.arrival_date) : dayjs(b.dispatch_date);
        return dateA.isAfter(dateB) ? -1 : 1;
    });

    const filteredData = combinedData.filter(item => {
        if (!startDate || !endDate) return true;

        const itemDate = item.type === 'arrival' ? dayjs(item.arrival_date) : dayjs(item.dispatch_date);
        if (!itemDate.isValid()) return false; // Exclude items without valid dates

        return itemDate.isBetween(startDate, endDate, null, '[]');
    });

    const clearFilters = () => {
        setStartDate(null);
        setEndDate(null);
    };

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 2 }}>
                <Button variant="contained" onClick={goBack} sx={{ marginRight: '16px' }}>
                    Назад
                </Button>

                {/* Date Range Filter */}
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Дата начала"
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                            sx={{ width: '200px', '& .MuiInputBase-root': { fontSize: '14px' } }}
                        />
                        <DatePicker
                            label="Дата окончания"
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                            sx={{ width: '200px', '& .MuiInputBase-root': { fontSize: '14px' } }}
                        />
                    </LocalizationProvider>
                    <Button
                        variant="contained"
                        onClick={clearFilters}
                        sx={{ padding: '4px 8px', fontSize: '12px', minWidth: 'auto' }}
                    >
                        Сбросить фильтры
                    </Button>
                </Box>

                {/* Legend */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
                        <Box sx={{ width: 16, height: 16, backgroundColor: 'lightgreen', marginRight: 0.5 }} />
                        <Typography variant="body2">Поступления</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
                        <Box sx={{ width: 16, height: 16, backgroundColor: 'lightcoral', marginRight: 0.5 }} />
                        <Typography variant="body2">Выдано</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 16, height: 16, backgroundColor: 'lightyellow', marginRight: 0.5 }} />
                        <Typography variant="body2">Возврат</Typography>
                    </Box>
                </Box>
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Название</TableCell>
                            <TableCell>Ед. изм</TableCell>
                            <TableCell>Цена за ед</TableCell>
                            <TableCell>Дата</TableCell>
                            <TableCell>Количество</TableCell>
                            <TableCell>Код</TableCell>
                            <TableCell>Примечание</TableCell>
                            <TableCell>Транспортное средство</TableCell>
                            <TableCell>Документ</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.map((item, index) => (
                            <TableRow
                                key={`${item.type}-${index}`}
                                style={{ backgroundColor: item.type === 'arrival' ? 'lightgreen' : item.status === 'returned' ? 'lightyellow' : 'lightcoral' }}
                            >
                                <TableCell>{historyData.name}</TableCell>
                                <TableCell>{historyData.unit}</TableCell>
                                <TableCell>{historyData.price}</TableCell>
                                <TableCell>
                                    {item.type === 'arrival'
                                        ? dayjs(item.arrival_date).format('DD.MM.YYYY')
                                        : item.dispatch_date
                                            ? dayjs(item.dispatch_date).format('DD.MM.YYYY')
                                            : 'Не выдано'}
                                </TableCell>
                                <TableCell>{item.quantity}</TableCell>
                                <TableCell>{historyData.code}</TableCell>
                                <TableCell sx={{width: '100%'}}>
                                    {item.type === 'arrival' && editingNoteId === item.id ? (
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <TextField
                                                fullWidth
                                                value={noteText}
                                                onChange={(e) => setNoteText(e.target.value)}
                                                size="small"
                                                sx={{ marginRight: 1, fontSize: 1
                                            }}
                                            />
                                            <IconButton
                                                onClick={() => handleSaveNote(item.id)}
                                                size="small"
                                            >
                                                <CheckIcon />
                                            </IconButton>
                                        </Box>
                                    ) : (
                                        <>
                                            {item.note}
                                            {item.type === 'arrival' && (
                                                <IconButton
                                                    onClick={() => handleEditNote(item.id, item.note)}
                                                    size="small"
                                                    sx={{ marginLeft: 1 }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            )}
                                        </>
                                    )}
                                </TableCell>
                                <TableCell>{item.type === 'dispatch' ? item.vehicle?.full_name : ''}</TableCell>
                                <TableCell>
                                    {item.type === 'dispatch' && (
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                item.maintenance_id ?  handleDownloadMaintenanceReport(item.maintenance_id) : handleDownloadInstallationReport(item.repair_work_item_id)
                                            }}
                                            size="small"
                                        >
                                            Скачать
                                        </Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default HistoryTable;
