import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import {IInventoryArrival, IInventoryOverview, InventoryApi} from '../../api/inventoryApi';
import {useSearchParams} from 'react-router-dom';
import styles from './InventoryNew.module.scss';
import HistoryTable from "./components/HistoryTable";
import dayjs from "dayjs";
import InventoryArrivalModal from "./components/InventoryArrivalModal";
import InventoryDispatchModal from "./components/InventoryDispatchModal";

const InventoryNew = () => {
    const inventoryApi = new InventoryApi();
    const [inventory, setInventory] = useState<IInventoryOverview[]>([]);
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const [arrivalModalOpen, setArrivalModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [isHistoryView, setIsHistoryView] = useState(false);
    const [historyData, setHistoryData] = useState<IInventoryOverview | null>(null);
    const [dispatchModalOpen, setDispatchModalOpen] = useState(false);
    const [selectedInventoryId, setSelectedInventoryId] = useState<number | null>(null);
    const [vehicleNames, setVehicleNames] = useState<string[]>([]);//used for note update
    const [tempSelectedVehicleNames, setTempSelectedVehicleNames] = useState<string[]>([]);
    const refreshInventory = async () => {
        const data = await inventoryApi.getInventoryOverview({});
        setInventory(data);
        // Update historyData if it's currently being shown
        if (historyData) {
            const updatedItem = data?.find(item => item.id === historyData.id);
            if (updatedItem) {
                setHistoryData(updatedItem);
            }
        }
    };

    const handleOpenDispatchModal = (inventoryId: number) => {
        setSelectedInventoryId(inventoryId);
        setDispatchModalOpen(true);
    };
    // Fetch inventory based on vehicle type IDs
    const fetchInventory = async (vehicleNames?: string[]) => {
        const data = await inventoryApi.getInventoryOverview({
            vehicle_names: vehicleNames && vehicleNames.length > 0 ? vehicleNames : undefined,
        });
        setInventory(data);
    };

    const fetchVehicleNames = async () => {
        const data = await inventoryApi.getVehicleNames();
        setVehicleNames(data);
    }

    useEffect(() => {
        fetchVehicleNames();
        const names = searchParams.get('vehicle_names');
        const vehicleNamesFromParams = names ? names.split(',') : [];
        setTempSelectedVehicleNames(vehicleNamesFromParams);
        fetchInventory(vehicleNamesFromParams);
    }, [searchParams]);

    const showHistory = (item: IInventoryOverview) => {
        setHistoryData(item);
        setIsHistoryView(true);
    };

    const goBack = () => {
        setIsHistoryView(false);
        setHistoryData(null);
    };

    const applyFilters = () => {
        if (tempSelectedVehicleNames.length > 0) {
            setSearchParams({ vehicle_names: tempSelectedVehicleNames.join(',') });
        } else {
            setSearchParams({});
        }
        fetchInventory(tempSelectedVehicleNames); // Fetch inventory based on the selected filters
        setFilterModalOpen(false);
    };

    const toggleSelectAll = () => {
        if (tempSelectedVehicleNames.length === vehicleNames.length) {
            setTempSelectedVehicleNames([]);
        } else {
            setTempSelectedVehicleNames(vehicleNames);
        }
    };

    const handleFilterChange = (name: string) => {
        setTempSelectedVehicleNames(prev => {
            if (prev.includes(name)) {
                return prev?.filter(vehicleName => vehicleName !== name);
            } else {
                return [...prev, name];
            }
        });
    };


    const handleSaveArrival = async (arrival: IInventoryArrival) => {
        if (arrival.id) {
            const payload = {
                id: arrival.id,
                quantity: arrival.quantity,
                note: arrival.note,
                provider: arrival.provider,
                is_common_item: false,
                type:"basic",
                group_id: 5
            };
            await inventoryApi.addInventoryArrival(payload);
        } else {
            const { id, ...newArrivalPayload } = arrival;
            await inventoryApi.addInventoryArrival({...newArrivalPayload, group_id: 5, type:'basic' });
        }
        await fetchInventory();
        setArrivalModalOpen(false);
    };

    return (
        <>
            {isHistoryView && historyData ? (
                <HistoryTable historyData={historyData} goBack={goBack}  onRefreshInventory={refreshInventory}/>
            ) : (
                <>
                    <div style={{display: 'flex', gap: '16px'}}>
                    <Button variant="contained" onClick={() => setFilterModalOpen(true)} className={styles.filterButton}>
                        Фильтры
                    </Button>
                    <Button variant="contained" onClick={() => setArrivalModalOpen(true)} className={styles.filterButton}>
                        Приход
                    </Button>
                    </div>
                    <Dialog open={filterModalOpen}>
                        <DialogTitle>Фильтр по типам транспорта</DialogTitle>
                        <DialogContent>
                            <TextField
                                placeholder="Поиск"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={tempSelectedVehicleNames.length === vehicleNames.length}
                                        onChange={toggleSelectAll}
                                    />
                                }
                                label="(Выбрать все)"
                            />
                            <Box sx={{ maxHeight: 200, overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
                                {vehicleNames
                                    ?.filter(name => name.toLowerCase().includes(searchTerm.toLowerCase())).map(name => (
                                        <FormControlLabel
                                            key={name}
                                            control={
                                                <Checkbox
                                                    checked={tempSelectedVehicleNames.includes(name)}
                                                    onChange={() => handleFilterChange(name)}
                                                />
                                            }
                                            label={name}
                                        />
                                    ))}
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setFilterModalOpen(false)}>Отмена</Button>
                            <Button onClick={applyFilters}>OK</Button>
                        </DialogActions>
                    </Dialog>
                <TableContainer component={Paper} className={styles.tableContainer}>
                    <Table>
                        <TableHead className={styles.tableHeader}>
                            <TableRow>
                                <TableCell>№</TableCell>
                                <TableCell>Дата последнего прихода</TableCell>
                                <TableCell>Наименование</TableCell>
                                <TableCell>Код</TableCell>
                                <TableCell>Назначение</TableCell>
                                <TableCell>Ед. изм</TableCell>
                                <TableCell>Кол-во (факт)</TableCell>
                                <TableCell>Итого</TableCell>
                                <TableCell>Цена за ед</TableCell>
                                <TableCell>Сумма</TableCell>
                                <TableCell>Поставщик</TableCell>
                                <TableCell>Дата последней выдачи</TableCell>
                                <TableCell>Выдано на ТС</TableCell>
                                <TableCell>№ заявки</TableCell>
                                <TableCell>Примечание</TableCell>
                                <TableCell>Действие</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {inventory?.map((item, index) => {
                                const hasPendingStatus = item.dispatches.some(dispatch => dispatch.status === 'pending');
                                return (
                                <TableRow key={index} className={styles.tableRow}>
                                    <TableCell>
                                        <div style={{display: 'inline-block', whiteSpace: 'nowrap'}}>
                                            {index + 1}
                                            {hasPendingStatus && (
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        width: '8px',
                                                        height: '8px',
                                                        backgroundColor: 'red',
                                                        borderRadius: '50%',
                                                        marginLeft: '5px',
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </TableCell>
                                    <TableCell>{item?.last_arrival ? dayjs(item.last_arrival).format('DD.MM.YYYY') : ''}</TableCell>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.code}</TableCell>
                                    <TableCell>{item.vehicles?.map(a => a).join(', ')}</TableCell>
                                    <TableCell>{item.unit}</TableCell>
                                    <TableCell>{item.quantity}</TableCell>
                                    <TableCell>
                                        {item.dispatches
                                            .filter((dispatch) => dispatch.status === 'approve')
                                            .reduce((total, dispatch) => total + dispatch.quantity, 0)}
                                    </TableCell>
                                    <TableCell>{item.price}</TableCell>
                                    <TableCell>{item.total_price}</TableCell>
                                    <TableCell>{item.arrivals[item?.arrivals.length -1]?.provider}</TableCell>
                                    <TableCell>{item?.last_dispatch ? dayjs(item.last_dispatch).format('DD.MM.YYYY') : ''}</TableCell>
                                    <TableCell>{item.dispatches[item?.dispatches?.length - 1]?.vehicle?.full_name}</TableCell>
                                    <TableCell>{item?.dispatches?.length}</TableCell>
                                    <TableCell>{item?.last_arrival_note || item?.last_dispatch_note}</TableCell>
                                    <TableCell>
                                        <IconButton className={styles.historyButton} onClick={() => showHistory(item)}>
                                            <HistoryIcon fontSize="small" />
                                        </IconButton>
                                        <Button
                                            variant="contained"
                                            className={styles.issueButton}
                                            onClick={() => handleOpenDispatchModal(item.id)}
                                        >
                                            Выдать
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                )})}
                        </TableBody>
                    </Table>
                </TableContainer>
                    <InventoryArrivalModal
                        open={arrivalModalOpen}
                        onClose={() => setArrivalModalOpen(false)}
                        vehicleNames={vehicleNames}
                        inventoryOptions={inventory}
                        onSave={handleSaveArrival}
                    />
                    <InventoryDispatchModal
                        open={dispatchModalOpen}
                        onClose={() => setDispatchModalOpen(false)}
                        inventoryId={selectedInventoryId}
                        onRefresh={() => fetchInventory()}
                    />
                </>
            )}
        </>
    );
};

export default InventoryNew;