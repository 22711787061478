import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Routes, Route, Navigate, useLocation,
} from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import Cookies from 'js-cookie';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LoginPage from './pages/LoginPage/LoginPage';
import Sidebar from './pages/Sidebar/Sidebar';
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import MaintenancePage from './pages/MaintenancePage/MaintenancePage';
import {AppProvider, useAppContext} from './pages/store/AppContext';
import VehiclesMain from "./pages/VehiclesPage/VehiclesMain";
import MultiStepForm from "./pages/VehiclesPage/MultiStepForm/MultiStepForm";
import VehicleInfo from "./pages/VehiclesPage/VehicleInfo/VehicleInfo";
import TemplatesMain from "./pages/TemplatesPage/TemplatesMain";
import VehicleTypesMain from "./pages/VehicleTypesPage/VehicleTypesMain";
import DriversMain from "./pages/DriversPage/DriversMain";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import VehicleRepairForm from "./pages/VehiclesPage/VehicleRepair/VehicleRepairForm";
import MaintenanceReport from "./pages/MaintenancePage/MaintenanceReport/MaintenanceReport";
import DocumentsPage from "./pages/DocumentsPage/DocumentsPage";
import InventoryMain from "./pages/InventoryPage/InventoryMain";
import { ruRU } from '@mui/x-data-grid/locales';
import RegisterMain from "./pages/RegisterPage/RegisterMain";
import ApplicationPartsPage from "./pages/RegisterPage/ApplicationPartsPage";
import CandidateOffersPage from "./pages/RegisterPage/CandidateOffersPage";
import StatsPage from "./pages/StatsPage/StatsPage";

const theme = createTheme({
    palette: {
        primary: {
            main: '#7e57c2',
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                    },
                    '& input[type=number]': {
                        '-moz-appearance': 'textfield',  // For Firefox
                    },
                },
            },
        },
    },
}, ruRU);

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    const isAuthenticated = !!Cookies.get('access_token');
    return isAuthenticated ? children : <Navigate to="/login" />;
};

const AppContent: React.FC = () => {
    const { isAuthenticated, updateAuthentication } = useAppContext();
    const location = useLocation();

    useEffect(() => {
        updateAuthentication();
    }, [location]);

    return (
        <Box sx={{ display: 'flex' }}>
            {isAuthenticated && <Sidebar />}
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, ml: isAuthenticated ? 30 : 0 }}
            >
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route
                        path="/maintenance"
                        element={
                            <ProtectedRoute>
                                <MaintenancePage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/vehicles"
                        element={
                            <ProtectedRoute>
                                <VehiclesMain />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/new-vehicle/"
                        element={
                            <ProtectedRoute>
                                <MultiStepForm />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/edit-vehicle/:id"
                        element={
                            <ProtectedRoute>
                                <MultiStepForm />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/vehicle-info/:id"
                        element={
                            <ProtectedRoute>
                                <VehicleInfo />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/inventory"
                        element={
                            <ProtectedRoute>
                                <InventoryMain />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/templates"
                        element={
                            <ProtectedRoute>
                                <TemplatesMain />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/vehicle_types"
                        element={
                            <ProtectedRoute>
                                <VehicleTypesMain />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/drivers"
                        element={
                            <ProtectedRoute>
                                <DriversMain />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/repair/:id/:repairId"
                        element={
                            <ProtectedRoute>
                                <VehicleRepairForm />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/maintenance/report/:vehicleId/:date/:maintenanceId?"
                        element={
                            <ProtectedRoute>
                                <MaintenanceReport />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/documents"
                        element={
                            <ProtectedRoute>
                                <DocumentsPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/register"
                        element={
                            <ProtectedRoute>
                                <RegisterMain />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/register/:id"
                        element={
                            <ProtectedRoute>
                                <ApplicationPartsPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/stats"
                        element={
                            <ProtectedRoute>
                                <StatsPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/register/:id/:partId"
                        element={
                            <ProtectedRoute>
                                <CandidateOffersPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="/" element={<Navigate to={isAuthenticated ? "/maintenance" : "/login"} />} />
                    <Route path="*" element={isAuthenticated ? <NotFoundPage /> : <Navigate to="/login" />} />
                </Routes>
            </Box>
        </Box>
    );
};

const App: React.FC = () => (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
            <AppProvider>
                <AppContent />
            </AppProvider>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Router>
    </ThemeProvider>
);

export default App;
