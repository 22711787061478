import React, { useState } from 'react';
import { Modal, Button, Select, MenuItem, FormControl, InputLabel, Typography } from '@mui/material';
import { Box } from '@mui/system';

interface ConditionChangeModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (status: string) => void;
}

const ConditionChangeModal: React.FC<ConditionChangeModalProps> = ({ open, onClose, onSave }) => {
    const [selectedStatus, setSelectedStatus] = useState<string>('');

    const handleSave = () => {
        onSave(selectedStatus);
        handleClose();
    };

    const handleClose = () => {
        setSelectedStatus('');
        onClose();
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{
                padding: '20px',
                backgroundColor: '#fff',
                margin: '100px auto',
                width: '400px',
                borderRadius: '8px', // Add border-radius for the modal
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
            }}>
                {/* Modal Title */}
                <Typography variant="h6" align="center" gutterBottom>
                    Изменить статус ТС
                </Typography>

                <FormControl fullWidth>
                    <Select
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value as string)}
                    >
                        <MenuItem value="В работе">В работе</MenuItem>
                        <MenuItem value="Требует ремонта">Требует ремонта</MenuItem>
                        <MenuItem value="В ожидании ремонта">В ожидании ремонта</MenuItem>
                        <MenuItem value="На консервации">На консервации</MenuItem>
                    </Select>
                </FormControl>

                <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="contained" onClick={handleSave} color="primary">
                        ОК
                    </Button>
                    <Button variant="outlined" onClick={handleClose} color="secondary">
                        ОТМЕНА
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConditionChangeModal;
