import {Box, Tab, Tabs} from '@mui/material';
import React, {useState} from 'react';
import InventoryNew from "./InventoryNew";
import FuelAccounting from "./pages/Fuel/FuelAccounting";
import EquipmentList from "./pages/Equipment/EquipmentList";
import HistoryMain from "./pages/HistoryPage/HistoryMain";

const InventoryMain = () => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    return (
        <Box>
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label="Склад" />
                <Tab label="Потребность УТМ" />
                <Tab label="ГСМ и О.Ж." />
                <Tab label="История" />
            </Tabs>
            <Box mt={2}>
                {activeTab === 0 && <InventoryNew />}
                {activeTab === 1 && <EquipmentList />}
                {activeTab === 2 && <FuelAccounting />}
                {activeTab === 3 && <HistoryMain />}
            </Box>
        </Box>
    );
};

export default InventoryMain;