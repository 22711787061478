import React, { useState } from "react";
import { Modal, Box, Typography, Button, Table, TableBody, TableCell, TableRow, TextField } from "@mui/material";

interface EquipmentDispatchModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: (data: { inventory_id: number; status: string; issued_to: string; dispatch_type: string; quantity: number }) => void;
    equipment: {
        id: number; // Added to match IDispatchEquipment
        name: string;
        remaining: number;
    } | null;
}

const EquipmentDispatchModal: React.FC<EquipmentDispatchModalProps> = ({
                                                                           open,
                                                                           onClose,
                                                                           onConfirm,
                                                                           equipment,
                                                                       }) => {
    const [quantity, setQuantity] = useState(0);
    const [recipient, setRecipient] = useState("");

    const handleConfirm = () => {
        if (!equipment || quantity <= 0 || !recipient.trim()) {
            alert("Заполните все поля корректно!");
            return;
        }
        onConfirm({
            inventory_id: equipment.id,
            status: "approve",
            issued_to: recipient,
            dispatch_type: "utm",
            quantity,
        });
        onClose();
        setQuantity(0);
        setRecipient("");
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    p: 4,
                    borderRadius: 2,
                    boxShadow: 24,
                }}
            >
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Выдать оборудование
                </Typography>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Наименование:</TableCell>
                            <TableCell>{equipment?.name || "-"}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>В остатке:</TableCell>
                            <TableCell>{equipment?.remaining || 0} шт</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Количество:</TableCell>
                            <TableCell>
                                <TextField
                                    type="number"
                                    value={quantity}
                                    onChange={(e) => setQuantity(Number(e.target.value))}
                                    fullWidth
                                    size="small"
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Получатель:</TableCell>
                            <TableCell>
                                <TextField
                                    value={recipient}
                                    onChange={(e) => setRecipient(e.target.value)}
                                    placeholder="Механик Ф.И.О."
                                    fullWidth
                                    size="small"
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
                    <Button variant="outlined" onClick={onClose}>
                        Отмена
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleConfirm}>
                        Выдать
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default EquipmentDispatchModal;
