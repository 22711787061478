// DateRangePickerModal.tsx
import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

interface DateRangePickerModalProps {
    open: boolean;
    onClose: () => void;
    onDownload: (startDate: string, endDate: string) => void;
}

const DateRangePickerModal: React.FC<DateRangePickerModalProps> = ({ open, onClose, onDownload }) => {
    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(null);

    const handleDownload = () => {
        if (startDate && endDate) {
            onDownload(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
            onClose();
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Выберите диапазон дат</DialogTitle>
            <DialogContent>
                <TextField
                    label="Начальная дата"
                    type="date"
                    fullWidth
                    value={startDate ? startDate.format('YYYY-MM-DD') : ''}
                    onChange={(e) => setStartDate(dayjs(e.target.value))}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="normal"
                />
                <TextField
                    label="Конечная дата"
                    type="date"
                    fullWidth
                    value={endDate ? endDate.format('YYYY-MM-DD') : ''}
                    onChange={(e) => setEndDate(dayjs(e.target.value))}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">Отмена</Button>
                <Button onClick={handleDownload} color="primary">Скачать</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DateRangePickerModal;
