import API from "./api";

export interface IApplication {
    type: string;
    stage: string;
    project_id: number;
    project_name: string;
    project_code: string;
    initiator_fullname: string;
    initiator_phone: string;
    initiator_position: string;
    priority: string;
    status: string;
    title: string;
    description: string;
    total_cost: number;
    comments: string;
    details: {};
    linked_application_parts: IApplicationPart[]
    id: number;
    created_at: string;
    created_by: number;
}

export interface IApplicationHistory {
    application_id: number;
    stage: string;
    verdict: string;
    comments: string;
    id: number;
    is_actual: true;
    start_date: string;
    end_date: string;
    created_at: string;
    created_by: number;
}

export interface IApplicationPart {
    application_id: number;
    type: string;
    name: string;
    description: string;
    quantity: number;
    inventory_item_id: number;
    attributes: {};
    comments: string;
    id: number;
    created_at: string;
    created_by: number;
    unit: string;
    expense_code: string;
    vehicle: {
        id: number;
        color: string;
        marka: string;
        model: string;
        gos_number: string;
        garage_number: string;
    }
}
export interface IProjects {
    code: string;
    name: string;
    id: number;
    created_at: string;
    created_by: number;
}

export interface ICandidateOffer {
    application_part_id: number;
    comments: string;
    created_at: string;
    created_by: number;
    currency: string;
    id: number;
    provider_id: number;
    provider_name: string;
    status: string;
    total_amount: number;
    total_amount_kzt: number;
}
export interface ICreateCandidateOffer {
    provider_id: number;
    total_amount: number;
    currency: string;
    comments: string;
}
export interface IProviders {
    name: string;
    address: string;
    phone: string;
    email: string;
    contact_person: string;
    comments: string;
    id: number;
    created_at: string;
    created_by: number;
}

export interface IInvoices{
    candidate_offer_id: number;
    created_at: string;
    created_by: number;
    currency: string;
    exchange_rate: number;
    id: number;
    invoice_number: string;
    invoice_title: string;
    payment_amount: number;
    payment_amount_kzt: number;
    payment_date_actual: string;
    payment_date_requested: string;
    provider_id: number;
    provider_name: string;
    status: string;
}

export interface IUploadDoc {
    document_path: string;
    document_title: string;
    document_type: string;
    linked_object_id: number;
    id:number;
}
export interface INotification {
    title: string;
    message: string;
    link: string;
    read: boolean;
    id: number;
    role: string;
    created_at: string;
}

export class RegisterApi extends API {
    getProjects(): Promise<IProjects[]> {
        return this.get('api/projects');
    }
    getApplications(role:string): Promise<IApplication[]> {
        return this.get(`api/applications?role=${role}`);
    }
    getApplicationHistory(id:number): Promise<IApplicationHistory[]> {
        return this.get(`api/applications/${id}/history`);
    }
    getApplicationsForDelete(): Promise<IApplication[]> {
        return this.get(`api/applications?status=На удаление`);
    }
    getAllApplications(): Promise<IApplication[]> {
        return this.get(`api/applications`);
    }
    getFinishedApplications(): Promise<IApplication[]> {
        return this.get(`api/applications?status=Завершена`);
    }
    postApplications(data: IApplication[], role: string): Promise<any> {
        return this.post(`api/applications?role=${role}`, data);
    }
    getApplicationById(id: number): Promise<IApplication> {
        return this.get(`api/applications/${id}`);
    }
    putApplication(id: number, data: IApplication) {
        return this.put(`api/applications/${id}`, data)
    }
    deleteApplication(id: number, role: string): Promise<any> {
        return this.delete(`api/applications/${id}?role=${role}`);
    }
    postApplicationParts(id: number, data: IApplicationPart) {
        return this.post(`api/applications/${id}/application_parts`, data);
    }
    getApplicationParts(id: number):Promise<IApplicationPart[]> {
        return this.get(`api/applications/${id}/application_parts`);
    }
    getApplicationPartById(id: number, application_part_id: number):Promise<IApplicationPart> {
        return this.get(`api/applications/${id}/application_parts/${application_part_id}`);
    }
    putApplicationPartById(id: number, application_part_id: number, data: IApplicationPart) {
        return this.put(`api/applications/${id}/application_parts/${application_part_id}`, data);
    }
    deleteApplicationPartById(id: number, application_part_id: number) {
    return this.delete(`api/applications/${id}/application_parts/${application_part_id}`);
    }
    sanctionApplication(id: number, role: string, comments: string, target_stage?: string) {
        const params = new URLSearchParams();
        params.append("role", role);
        if (target_stage) {
            params.append("target_stage", target_stage);
        }
        return this.post(`api/applications/${id}/sanction?${params.toString()}`, {comments});
    }
    revertApplication(id:number, role: string, target_stage:string, comments: string) {
        return this.post(`api/applications/${id}/revert?role=${role}&target_stage=${target_stage}`, {comments});
    }
    getCandidateOffers(id?: string, partId?: string):Promise<ICandidateOffer[]> {
        return this.get(`api/applications/${id}/application_parts/${partId}/candidate_offers`);
    }
    createCandidateOffer(role: string, data: ICreateCandidateOffer, id?: string, partId?: string):Promise<ICandidateOffer[]> {
        return this.post(`api/applications/${id}/application_parts/${partId}/candidate_offers?role=${role}`, data);
    }
    getCandidateOfferById(candidate_offer_id: number, id?: string, partId?: string):Promise<ICandidateOffer> {
        return this.get(`api/applications/${id}/application_parts/${partId}/candidate_offers/${candidate_offer_id}`);
    }
    editCandidateOffer(candidate_offer_id: number, data: ICreateCandidateOffer, id?: string, partId?: string, role?:string):Promise<ICandidateOffer> {
        return this.put(`api/applications/${id}/application_parts/${partId}/candidate_offers/${candidate_offer_id}?role=${role}`, data)
    }
    deleteCandidateOffer(candidate_offer_id: number, id?: string, partId?: string, role?: string):Promise<ICandidateOffer> {
        return this.delete(`api/applications/${id}/application_parts/${partId}/candidate_offers/${candidate_offer_id}?role=${role}`)
    }
    approveCandidateOffer(candidate_offer_id: number, comments: string, role:string, id?: string, partId?: string, ) {
        return this.post(`/api/applications/${id}/application_parts/${partId}/candidate_offers/${candidate_offer_id}/approve?role=${role}`, {comments})
    }
    getProviders():Promise<IProviders[]> {
        return this.get(`api/providers`);
    }
    getInvoices(id:number, partId: number): Promise<IInvoices[]> {
        return this.get(`api/applications/${id}/application_parts/${partId}/invoices`);
    }
    postInvoices(id: number, partId:number, data: any, role: string):Promise<any> {
        return this.post(`api/applications/${id}/application_parts/${partId}/invoices?role=${role}`, data);
    }
    getInvoiceById(id:number, partId:number, invoice_id: number):Promise<IInvoices> {
        return this.get(`api/applications/${id}/application_parts/${partId}/invoices/${invoice_id}`)
    }
    editInvoice(id:number, partId:number, invoice_id: number, data: any, role: string):Promise<any> {
        return this.put(`api/applications/${id}/application_parts/${partId}/invoices/${invoice_id}?role=${role}`, data)
    }
    deleteInvoice(id:number, partId:number, invoice_id:number, role: string):Promise<any> {
        return this.delete(`api/applications/${id}/application_parts/${partId}/invoices/${invoice_id}?role=${role}`)
    }
    getDocument(id:number, type:string):Promise<IUploadDoc[]>{
        return this.get(`api/documents?document_type=${type}&linked_object_id=${id}`)
    }
    postDocument(data: Omit<IUploadDoc, 'id'>) {
        return this.post(`api/documents`, data);
    }
    downloadDoc(id:number):Promise<any> {
        return this.getBlob(`api/documents/${id}/download`);
    }
    deleteDocument(id:number){
        return this.delete(`api/documents/${id}`);
    }
    getNotifications(role: string): Promise<INotification[]> {
        return this.get(`api/notifications?role=${role}`);
    }
}