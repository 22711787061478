import API from './api';
import Cookies from "js-cookie";

interface LoginResponse {
    access_token: string;
    refresh_token: string;
}

export interface IVehicle {
    marka: string;
    model: string;
    vehicle_type_id: number | null;
    color: string;
    year: number | null;
    license_category: string;
    gos_number: string;
    serial_number: string;
    tech_passport_number: string;
    garage_number: string;
    num_of_seats: number | null;
    mass_unloaded: number | null;
    lifting_capacity: number | null;
    axis_number: number | null;
    height: number | null;
    length: number | null;
    width: number | null;
    wheel_size: number | null;
    mileage: number | null;
    moto_hours: number | null;
    condition: string;
    condition_comments: string;
    type_special_fields: Record<string, any>;
    doc_front_image: string;
    doc_registration: string;
    doc_tech_details: string;
    doc_insurance: string;
    doc_maintenance: string;
    id: number | null;
    created_by: number | null;
    updated_by: number | null;
    deleted_by: number | null;
    vehicle_type: string;
    driver?: string;
    default_tracking_type: string;
    doc_maintenance_full: string;
    doc_maintenance_partly: string;
    doc_passengers_insurance: string;
}
class AuthAPI extends API {
    login(username: string, password: string): Promise<LoginResponse> {
        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);

        return this.post<LoginResponse>('api/login', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then((response) => {
            Cookies.set('access_token', response.access_token);
            Cookies.set('refresh_token', response.refresh_token);
            return response;
        });
    }

    logout() {
        Cookies.remove('access_token');
        Cookies.remove('refresh_token');
    }
}

export default AuthAPI;
