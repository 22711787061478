import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, Autocomplete, TextField, Typography } from '@mui/material';
import {IAwaitingDispatches, InventoryApi} from "../../../api/inventoryApi";


interface InventoryDispatchModalProps {
    open: boolean;
    onClose: () => void;
    inventoryId: number | null;
    onRefresh: () => void;
}

const InventoryDispatchModal: React.FC<InventoryDispatchModalProps> = ({ open, onClose, inventoryId, onRefresh }) => {
    const [awaitingDispatches, setAwaitingDispatches] = useState<IAwaitingDispatches[]>([]);
    const [selectedDispatch, setSelectedDispatch] = useState<IAwaitingDispatches | null>(null);
    const [note, setNote] = useState('');
    const inventoryApi = new InventoryApi();
    useEffect(() => {
        if (inventoryId !== null) {
            // Mock fetching awaiting dispatches for the given inventoryId
            const fetchAwaitingDispatches = async () => {
                try {
                    const data = await inventoryApi.getDispatches(inventoryId);
                    setAwaitingDispatches(data.filter(dispatch => dispatch.status === 'pending'));
                } catch (e) {
                    console.log(e);
                }
            };
            fetchAwaitingDispatches();
        }
    }, [inventoryId]);

    const handleOk = async () => {
        try{
            if (selectedDispatch) {
                const requestData = {
                    vehicle_id: selectedDispatch.vehicle.id,
                    inventory_id: selectedDispatch.inventory_item.id,
                    maintenance_id: selectedDispatch.maintenance_id,
                    note,
                    status: 'approve',
                    dispatch_id: selectedDispatch.id,
                };
                await inventoryApi.dispatchInventoryItem(requestData);
                onRefresh();
                onClose();
            }
        }
        catch (e) {
            console.log(e)
        }
    };
    const handleClose = () => {
        setSelectedDispatch(null);
        setNote('');
        onClose();
    };

    return (
        <Modal open={open} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClose={handleClose}>
            <Box sx={{ backgroundColor: 'white', p: 4, borderRadius: 2, maxWidth: 500, width: '100%' }}>
                <Typography variant="h6" gutterBottom>
                    Выдача
                </Typography>
                <Autocomplete
                    options={awaitingDispatches}
                    getOptionLabel={(option) => option?.vehicle.full_name}
                    onChange={(event, value) => setSelectedDispatch(value)}
                    renderInput={(params) => <TextField {...params} label="Выберите выдачу" />}
                />
                {selectedDispatch && (
                    <>
                        <TextField
                            label="Складская позиция"
                            value={selectedDispatch.inventory_item.name}
                            fullWidth
                            margin="normal"
                            InputProps={{ readOnly: true }}
                        />
                        <TextField
                            label="Запрашиваемое количество"
                            value={selectedDispatch.inventory_item.quantity}
                            fullWidth
                            margin="normal"
                            InputProps={{ readOnly: true }}
                        />
                        <TextField
                            label="Примечание"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Button onClick={onClose} variant="outlined">
                        Отменить
                    </Button>
                    <Button onClick={handleOk} variant="contained" color="primary">
                        Выдать
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default InventoryDispatchModal;
