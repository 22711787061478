import React from 'react';
import { Autocomplete, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { IRepairCards } from '../../../api/vehiclesApi';
import dayjs from 'dayjs';

interface RepairCardSelectorProps {
    repairCards: IRepairCards[];
    selectedRepairCard: number | null;
    onSelect: (value: number | null) => void;
    open: boolean;
    onClose: () => void;
    onDownload: () => void;
}

const RepairCardsListModal: React.FC<RepairCardSelectorProps> = ({
                                                                     repairCards,
                                                                     selectedRepairCard,
                                                                     onSelect,
                                                                     open,
                                                                     onClose,
                                                                     onDownload
                                                                 }) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>Выберите наряд-задание</DialogTitle>
            <DialogContent>
                <Autocomplete
                    options={repairCards}
                    getOptionLabel={(option) =>
                        `Наряд-задание от ${dayjs(option.created_at).format('DD.MM.YYYY')}, статус: ${option.status}, выполнено: ${option.card_opened_by || 'Не указано'}`
                    }
                    value={repairCards.find((card) => card.id === selectedRepairCard) || null}
                    onChange={(event, newValue) => onSelect(newValue ? newValue.id : null)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                        />
                    )}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} sx={{ color: 'grey.600' }}>Отмена</Button>
                <Button onClick={onDownload} variant="contained" sx={{ backgroundColor: '#6A1B9A' }}>
                    Скачать
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RepairCardsListModal;
