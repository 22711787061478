import React, { FC } from 'react';
import {
    Box,
    Button,
    Modal,
    TextField,
    Typography,
    Autocomplete, MenuItem,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {ICreateCandidateOffer} from "../../../api/registerApi";

interface CandidateOfferModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (data: ICreateCandidateOffer) => void;
    providers: { name: string; id: number }[];
    initialData?: Partial<ICreateCandidateOffer>;
}

const CreateCandidateOffer: FC<CandidateOfferModalProps> = ({
                                                               open,
                                                               onClose,
                                                               onSubmit,
                                                               providers,
                                                               initialData = {},
                                                           }) => {
    const validationSchema = Yup.object({
        provider_id: Yup.number().required('Пожалуйста, выберите поставщика.'),
        total_amount: Yup.string()
            .required('Сумма обязательна.')
            .matches(/^\d+(\.\d{1,2})?$/, 'Сумма должна быть числом.'),
        currency: Yup.string().required('Валюта обязательна.'),
        comments: Yup.string(),
    });

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="candidate-offer-modal-title"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}
            >
                <Typography id="candidate-offer-modal-title" variant="h6" gutterBottom>
                    {initialData.provider_id ? 'Редактировать предложение' : 'Создать предложение'}
                </Typography>
                <Formik
                    initialValues={{
                        provider_id: initialData.provider_id || null,
                        total_amount: initialData.total_amount?.toString() || '',
                        currency: initialData.currency || '',
                        comments: initialData.comments || '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        onSubmit({
                            provider_id: values.provider_id!,
                            total_amount: parseFloat(values.total_amount),
                            currency: values.currency,
                            comments: values.comments,
                        });
                        onClose();
                    }}
                >
                    {({ values, setFieldValue, errors, touched }) => (
                        <Form>
                            <Autocomplete
                                options={providers}
                                getOptionLabel={(option) => option.name}
                                value={providers.find((provider) => provider.id === values.provider_id) || null}
                                onChange={(event, newValue) =>
                                    setFieldValue('provider_id', newValue ? newValue.id : null)
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Поставщик"
                                        error={touched.provider_id && !!errors.provider_id}
                                    />
                                )}
                                sx={{ mb: 2 }}
                            />
                            <Field
                                as={TextField}
                                name="total_amount"
                                label="Сумма"
                                fullWidth
                                error={touched.total_amount && !!errors.total_amount}
                                helperText={touched.total_amount && errors.total_amount}
                                sx={{ mb: 2 }}
                            />
                            <Field
                                as={TextField}
                                name="currency"
                                label="Валюта"
                                fullWidth
                                select
                                error={touched.currency && !!errors.currency}
                                helperText={touched.currency && errors.currency}
                                sx={{ mb: 2 }}
                            >
                                {["KZT", "RUB", "USD"].map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Field>

                            <Field
                                as={TextField}
                                name="comments"
                                label="Комментарий"
                                multiline
                                rows={3}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button variant="outlined" onClick={onClose}>
                                    Отмена
                                </Button>
                                <Button variant="contained" color="primary" type="submit">
                                    {initialData.provider_id ? 'Сохранить' : 'Создать'}
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Modal>
    );
};

export default CreateCandidateOffer;
