import API from "./api";

export interface IWarehouses{
    name: string;
    city: string;
    address: string;
    id: number;
}
export interface ICategory {
    id: number;
    name: string;
}
export interface IInventory{
    id: number;
    name: string;
    description: string;
    category_id: number;
    warehouse_id: number;
    quantity: number;
    volume: number;
    condition: string;
    comments: string;
    unit: string;
}

export interface ICreateInventory {

}
export interface IInventoryArrival {
    id?: number;
    name?: string;
    code?: string;
    quantity: number;
    unit?: string;
    price?: number;
    vehicle_names?: string[];
    note: string | null;
    provider: string;
    is_common_item: boolean;
}
export interface IDispatchInventoryItem {
    inventory_id: number;
    vehicle_id: number;
    maintenance_id: number;
    note: string;
    status?: string;
    dispatch_id: number;
}
export interface InventoryOverviewParams {
    vehicle_names?: string[];
    start_date?: string;
    end_date?: string;
}
export interface IInventoryOverview {
    id: number;
    code: string;
    name: string;
    last_arrival: string;
    last_dispatch: string;
    unit: string;
    quantity: number;
    price: number;
    total_price: number;
    provider: string;
    vehicles: string[];
    arrivals: {id: number, arrival_date: string, quantity: number, note: string, provider?: string}[];
    dispatches: {dispatch_date: string, quantity: number,
        vehicle: {id: number, full_name: string},
        note: string | null,
        status: string,
        maintenance_id: number,
        repair_work_item_id: number
    }[];
    last_arrival_note: string;
    last_dispatch_note: string;
}
export interface IAwaitingDispatches {
    id: number;
    status: string;
    note: string;
    dispatch_date: string;
    vehicle: {
        id: number;
        full_name: string;
    };
    inventory_item: {
        id: number;
        name: string;
        quantity: number;
    }
    maintenance_id: number;
}
export class InventoryApi extends API {
  getWarehouses(): Promise<IWarehouses[]> {
      return this.get(`api/inventory/warehouses`)
  }
  postWarehouses(data: Omit<IWarehouses, 'id'>): Promise<IWarehouses> {
      return this.post(`api/inventory/warehouses`, data)
  }
  getWarehouseById(id: number): Promise<IWarehouses> {
      return this.get(`api/inventory/warehouses/${id}`)
  }
  putWarehouse(id: number, data: Omit<IWarehouses, 'id'> ): Promise<IWarehouses> {
        return this.put(`api/inventory/warehouses/${id}`, data)
  }
  deleteWarehouse(id: number ): Promise<IWarehouses> {
        return this.delete(`api/inventory/warehouses/${id}`)
  }
  getCategories(): Promise<ICategory[]> {
        return this.get(`api/inventory/categories`)
  }
  postCategories(data: string): Promise<ICategory> {
        return this.post(`api/inventory/categories`, {name: data})
  }
  getCategoryById(id: number): Promise<ICategory> {
        return this.get(`api/inventory/categories/${id}`)
  }
  putCategory(id: number, data: Omit<IWarehouses, 'id'> ): Promise<IWarehouses> {
        return this.put(`api/inventory/categories/${id}`, data)
  }
  deleteCategory(id: number ): Promise<IWarehouses> {
        return this.delete(`api/inventory/categories/${id}`)
  }
  getInventory():Promise<IInventory[]> {
        return this.get(`api/inventory`)
  }
  postInventory(data: IInventory): Promise<IInventory> {
        return this.post(`api/inventory`, data)
  }
  getInventoryByItemId(itemId: number): Promise<IInventory> {
        return this.get(`api/inventory/${itemId}`)
  }
  editInventory(itemId: number, data: IInventory): Promise<IInventory> {
        return this.put(`api/inventory/${itemId}`, data)
  }
  deleteInventory(itemId: number) {
        return this.delete(`api/inventory/${itemId}`)
  }
  addInventoryArrival(data: IInventoryArrival) {
        return this.post(`api/inventory/items/arrival`, data);
    }
    dispatchInventoryItem(data: IDispatchInventoryItem) {
        return this.post(`api/inventory/items/dispatch`, data);
    }
    getInventoryOverview(data?: InventoryOverviewParams):Promise<IInventoryOverview[]> {
        return this.post<{ data: IInventoryOverview[] }>(`api/inventory/items/overview`, data).then(response => response.data);
    }
    getDispatches(id: number): Promise<IAwaitingDispatches[]> {
        return this.get<{ data: IAwaitingDispatches[] }>(`api/inventory/items/dispatches?inventory_item_id=${id}`)
            .then((response) => response.data); // Cast response to the expected type
    }
    refundItem(maintenance_id: string, inventory_item_id: string, item_target: string):Promise<any> {
      return this.post(`api/inventory/items/dispatches/return?inventory_item_id=${inventory_item_id}&item_target=${item_target}&item_target_id=${maintenance_id}`)
    }
    getDispatchesCount() :Promise<number>{
      return this.get(`api/inventory/items/dispatch/count`).then((response: any) => response.data.count)
    }
    updateInventoryArrival(data: {id: number, note: string}) {
      return this.patch(`api/inventory/items/arrival`, data)
    }
    getVehicleNames ():Promise<string[]> {
      return this.get(`api/vehicles/names`)
    }
}
