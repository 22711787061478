import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Select,
    MenuItem,
    TextField,
    FormControl,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputAdornment,
    Typography,
} from '@mui/material';
import { Add, Edit, Delete, Save, Remove } from '@mui/icons-material';
import { useFormik } from 'formik';
import styles from './styles.module.scss';
import { IPostTemplate, ITemplate, TemplatesApi } from '../../api/templatesApi';
import {IConsumableTypes, IPostConsumables, VehiclesApi} from '../../api/vehiclesApi';
import SectionDivider from "../VehiclesPage/VehicleInfo/components/SectionDivider";
import {IInventory, IInventoryOverview, InventoryApi} from "../../api/inventoryApi";

const TemplatesMain: React.FC = () => {
    const templatesApi = new TemplatesApi();
    const vehiclesApi = new VehiclesApi();
    const inventoryApi = new InventoryApi();
    const [templates, setTemplates] = useState<ITemplate[]>([]);
    const [consumableTypes, setConsumableTypes] = useState<IConsumableTypes[]>([]);
    const [inventory, setInventory] = useState<IInventoryOverview[]>([])
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
    const [currentTemplateId, setCurrentTemplateId] = useState<number | null>(null);
    const fetchTemplates = async () => {
        const data = await templatesApi.getTemplates();
        setTemplates(data);
    };

    const fetchConsumableTypes = async () => {
        try {
            const data = await vehiclesApi.getConsumablesTypes();
            setConsumableTypes(data);   
        }
        catch (e) {
            console.log(e);
        }
    };
    const fetchInventory = async () => {
        try {
            const data = await inventoryApi.getInventoryOverview({});
            setInventory(data);
        }
        catch (e) {
            console.log(e);
        }
    }
    const handleAdd = () => {
        formik.resetForm();
        setCurrentTemplateId(null);
        setIsFormVisible(true);
    };

    const handleEdit = async (id: number) => {
        try {
            const template = await templatesApi.getTemplateById(id);
            formik.setValues(template);
            setCurrentTemplateId(id);
            setIsFormVisible(true);
        }
        catch (e) {
            console.log(e);
        }
    };

    const handleDelete = async () => {
        if (currentTemplateId !== null) {
            await templatesApi.deleteTemplate(currentTemplateId);
            fetchTemplates();
            setIsDeleteConfirmVisible(false);
            setCurrentTemplateId(null);
        }
    };

    const confirmDelete = (id: number) => {
        setCurrentTemplateId(id);
        setIsDeleteConfirmVisible(true);
    };

    const getConsumableUnits = (typeId: number) => {
        const consumableType = consumableTypes.find((type) => type.id === typeId);
        return consumableType ? consumableType.interval_units : [];
    };

    const formik = useFormik<IPostTemplate>({
        initialValues: {
            template_name: '',
            consumable_type_id: null,
            consumption_rate: null,
            consumption_unit: '',
            consumption_rate_calculation_interval: null,
            replacement_interval: null,
            replacement_interval_unit: '',
            replacement_part_id: null,
            vehicle_consumable_volume: null,
            id: null,
        },
        onSubmit: async (values) => {
            const { id, ...templateData } = values; // Destructure to separate the id

            if (currentTemplateId) {
                await templatesApi.updateTemplate(currentTemplateId, values);
            } else {
                await templatesApi.createTemplate(templateData);
            }
            fetchTemplates();
            formik.resetForm();
            setIsFormVisible(false);
        },
    });

    useEffect(() => {
        fetchTemplates();
        fetchConsumableTypes();
        fetchInventory();
    }, []);

    return (
        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Название шаблона</TableCell>
                            <TableCell>Consumable Type</TableCell>
                            <TableCell>Replacement Part</TableCell>
                            <TableCell>Действия</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {templates.map((template) => (
                            <TableRow key={template.id}>
                                <TableCell>{template.template_name}</TableCell>
                                <TableCell>{template.consumable_type}</TableCell>
                                <TableCell>{template.replacement_part}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleEdit(template.id)} color="primary">
                                        <Edit />
                                    </IconButton>
                                    <IconButton onClick={() => confirmDelete(template.id)} color="error">
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button onClick={handleAdd} startIcon={<Add />} variant="contained" color="primary" style={{ marginTop: '16px' }}>
                Добавить шаблон
            </Button>

            {/* Add/Edit Modal */}
            <Dialog open={isFormVisible} onClose={() => setIsFormVisible(false)}>
                <DialogTitle>{currentTemplateId ? 'Редактировать шаблон' : 'Добавить Шаблон'}</DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent sx={{display:'flex', flexDirection: 'column'}}>
                        <TextField
                            name="template_name"
                            label="Название"
                            value={formik.values.template_name}
                            onChange={formik.handleChange}
                            className={styles.formControlWide}
                            margin="normal"
                            required
                        />
                        <FormControl className={styles.formControlWide} margin="normal">
                            <Select
                                name="consumable_type_id"
                                value={formik.values.consumable_type_id}
                                onChange={(e) => {
                                    const selectedType = consumableTypes.find(type => type.id === e.target.value);
                                    formik.setFieldValue('consumable_type_id', e.target.value);
                                    if (selectedType) {
                                        formik.setFieldValue('consumption_unit', selectedType.unit);
                                    }
                                }}
                                required
                                disabled={!!currentTemplateId}
                            >
                                {consumableTypes.map((type) => (
                                    <MenuItem key={type.id} value={type.id}>
                                        {type.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <SectionDivider text={'Норма потребления'}/>
                        <Box display="flex" gap="8px">
                            <TextField
                                name="consumption_rate"
                                type="number"
                                value={formik.values.consumption_rate || ''}
                                onChange={formik.handleChange}
                                className={styles.formControlNumber}
                                margin="normal"
                                required
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {formik.values.consumption_unit}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                name="consumption_rate_calculation_interval"
                                type="number"
                                value={formik.values.consumption_rate_calculation_interval || ''}
                                onChange={formik.handleChange}
                                className={styles.formControlNumber}
                                margin="normal"
                                required
                            />
                            <FormControl className={styles.formControlUnit} margin="normal">
                                <Select
                                    name="replacement_interval_unit"
                                    value={formik.values.replacement_interval_unit}
                                    onChange={formik.handleChange}
                                    required
                                >
                                    {getConsumableUnits(formik.values.consumable_type_id as number).map((unit, i) => (
                                        <MenuItem key={i} value={unit}>
                                            {unit}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                            <TextField
                                name="vehicle_consumable_volume"
                                label="Объем"
                                type="number"
                                value={formik.values.vehicle_consumable_volume || ''}
                                onChange={formik.handleChange}
                                className={styles.formControlNumber}
                                margin="normal"
                                required
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {formik.values.consumption_unit}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        <SectionDivider text={'Интервал замены'}/>
                        <Box display="flex" gap="8px">
                        <TextField
                            name="replacement_interval"
                            label="Интервал замены"
                            type="number"
                            value={formik.values.replacement_interval || ''}
                            onChange={formik.handleChange}
                            className={styles.formControlNumber}
                            margin="normal"
                            required
                        />
                        <FormControl className={styles.formControlUnit} margin="normal">
                            <Select
                                name="replacement_interval_unit"
                                value={formik.values.replacement_interval_unit}
                                onChange={formik.handleChange}
                                required
                            >
                                {getConsumableUnits(formik.values.consumable_type_id as number).map((unit, i) => (
                                    <MenuItem key={i} value={unit}>
                                        {unit}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                        <FormControl className={styles.formControlWide} margin="normal">
                            <Select
                                name="consumable_type_id"
                                value={formik.values.replacement_part_id}
                                onChange={(e) => {
                                    formik.setFieldValue('replacement_part_id', e.target.value);
                                }}
                                required
                                disabled={!!currentTemplateId}
                            >
                                {inventory?.map((type) => (
                                    <MenuItem key={type.id} value={type.id}>
                                        {type.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsFormVisible(false)} color="secondary">
                            Отмена
                        </Button>
                        <Button type="submit" startIcon={<Save />} variant="contained" color="primary">
                            Сохранить
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            {/* Delete Confirmation Modal */}
            <Dialog
                open={isDeleteConfirmVisible}
                onClose={() => setIsDeleteConfirmVisible(false)}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                       Вы действительно хотите удалить этот шаблон ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDeleteConfirmVisible(false)} color="secondary">
                        Отмена
                    </Button>
                    <Button onClick={handleDelete} color="primary">
                        Удалить
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default TemplatesMain;
