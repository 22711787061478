import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import dayjs from "dayjs";
import { IInventoryOverview, IArrivals, IDispatches, InventoryApi } from "../../../../api/inventoryApi";

interface HistoryRow {
    id: number;
    type: "arrival" | "dispatch";
    name: string;
    code: string;
    quantity: number;
    unit: string;
    date: string;
    provider: string;
    vehicle: string;
}

const HistoryMain = () => {
    const inventoryApi = new InventoryApi();
    const [history, setHistory] = useState<IInventoryOverview[]>([]);

    const handleGetHistory = async () => {
        try {
            const data = await inventoryApi.getInventoryHistory();
            setHistory(data);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        void handleGetHistory();
    }, []);

    // Flatten arrivals and dispatches into a single array
    const getSortedRows = (): HistoryRow[] => {
        const rows: HistoryRow[] = history.flatMap((item) => [
            ...item.arrivals.map((arrival): HistoryRow => ({
                id: arrival.id,
                type: "arrival", // Explicitly typed as "arrival"
                name: item.name,
                code: item.code,
                quantity: arrival.quantity,
                unit: item.unit,
                date: arrival.arrival_date,
                provider: arrival.provider,
                vehicle: ''
            })),
            ...item.dispatches.map((dispatch): HistoryRow => ({
                id: dispatch.id,
                type: "dispatch", // Explicitly typed as "dispatch"
                name: item.name,
                code: item.code,
                quantity: dispatch.quantity,
                unit: item.unit,
                date: dispatch.dispatch_date,
                provider: "",
                vehicle: dispatch.vehicle?.full_name || "",
            })),
        ]);
        // Sort rows by date
        return rows.sort((a, b) => dayjs(b.date).diff(dayjs(a.date)));
    };

    const sortedRows = getSortedRows();

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Наименование</TableCell>
                        <TableCell>Код</TableCell>
                        <TableCell>Количество</TableCell>
                        <TableCell>Ед. изм.</TableCell>
                        <TableCell>Дата</TableCell>
                        <TableCell>Поставщик</TableCell>
                        <TableCell>Выдан на ТС</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedRows.map((row, index) => (
                        <TableRow
                            key={`${row.type}-${row.id}`}
                            style={{
                                backgroundColor: row.type === "arrival" ? "lightgreen" : "lightcoral",
                            }}
                        >
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.code}</TableCell>
                            <TableCell>{row.quantity}</TableCell>
                            <TableCell>{row.unit}</TableCell>
                            <TableCell>{dayjs(row.date).format("DD.MM.YYYY")}</TableCell>
                            <TableCell>{row.provider}</TableCell>
                            <TableCell>{row.vehicle}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default HistoryMain;
