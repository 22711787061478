import React, {useEffect, useState} from 'react';
import {Button, Typography, Box, TextField} from '@mui/material';
import { useAppContext } from "../../../store/AppContext";
import { VehiclesApi } from "../../../../api/vehiclesApi";
import styles from './styles.module.scss';
import {IFormStep} from "../MultiStepForm";
import dayjs from 'dayjs';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";


const FormStep3: React.FC<IFormStep> = ({ handleNext, handleBack }) => {
    const {
        files,
        setFiles,
        fileNames,
        setFileNames,
        handleGatheredData,
        isAllUploadsSuccessful,
        setIsAllUploadsSuccessful,
    } = useAppContext();
    const vehicleApi = new VehiclesApi();

    const [uploadStatus, setUploadStatus] = useState<{ [key: string]: boolean }>({
        face: false,
        registration: false,
        technical: false,
        doc_insurance: false,
    });

    const [lastSavedFiles, setLastSavedFiles] = useState<{ [key: string]: string | null }>({
        face: null,
        registration: null,
        technical: null,
        doc_insurance: null,
    });
    const [dates, setDates] = useState<{ [key: string]: string | null }>({
        face: null,
        registration: null,
        technical: null,
        doc_insurance: null,
    });

    useEffect(() => {
        // Sync the last saved file names with the current fileNames when the component mounts
        setLastSavedFiles(fileNames);
    }, [fileNames]);

    const handleDateChange = (date: dayjs.Dayjs | null, type: string) => {
        setDates({
            ...dates,
            [type]: date ? dayjs(date).format('DD.MM.YYYY') : null,
        });
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
        if (e.target.files && e.target.files[0]) {
            setFiles({
                ...files,
                [type]: e.target.files[0],
            });
            setUploadStatus({
                ...uploadStatus,
                [type]: false,
            });
        }
    };

    const handleSave = async () => {
        let allSuccessful = true;
        for (const key of Object.keys(files) as (keyof typeof files)[]) {
            if (files[key] && (files[key]?.name !== lastSavedFiles[key])) {
                const formData = new FormData();
                formData.append('file', files[key] as File);
                try {
                    // Format the date to 'YYYY-MM-DD' before sending the request
                    const formattedDate = dates[key] ? dayjs(dates[key], 'DD.MM.YYYY').format('YYYY-MM-DD') : undefined;

                    const response = await vehicleApi.postVehicleDocs(formData, formattedDate);
                    setFileNames(prev => ({
                        ...prev,
                        [key]: response,
                    }));
                    setLastSavedFiles(prev => ({
                        ...prev,
                        [key]: files[key]?.name || null,
                    }));
                    setUploadStatus(prev => ({
                        ...prev,
                        [key]: true,
                    }));
                } catch (error) {
                    console.error(`Error uploading ${key}:`, error);
                    setUploadStatus(prev => ({
                        ...prev,
                        [key]: false,
                    }));
                    allSuccessful = false;
                }
            }
        }
        setIsAllUploadsSuccessful(allSuccessful);
    };

    const handleDeleteFile = (type: string) => {
        setFiles(prev => ({
            ...prev,
            [type]: null,
        }));
        setFileNames(prev => ({
            ...prev,
            [type]: null,
        }));
        setLastSavedFiles(prev => ({
            ...prev,
            [type]: null,
        }));
        setDates(prev => ({
            ...prev,
            [type]: null,
        }));
    };

    const anyFileUploaded = files.face || files.registration || files.technical || files.doc_insurance;
    const allFileNamesPresent = !!(fileNames.face && fileNames.registration && fileNames.technical && fileNames.doc_insurance);

// Save button is enabled if any file is uploaded
    const saveButtonDisabled = !anyFileUploaded;

// Submit button is enabled if all file names are present or if all uploads were successful
    const submitButtonDisabled = !(allFileNamesPresent || isAllUploadsSuccessful);



    return (
        <Box className={styles.container}>
            <Typography variant="h6" className={styles.title}>Step 3</Typography>

            {/* File and Date Upload Section */}
            {['face', 'registration', 'technical', 'doc_insurance'].map((type) => (
                <Box key={type} className={styles.uploadSection}>
                    <Typography variant="body1">
                        {type === 'face' && 'Лицевая сторона ТС'}
                        {type === 'registration' && 'Свидетельство о регистрации ТС'}
                        {type === 'technical' && 'Технические сведения о ТС'}
                        {type === 'doc_insurance' && 'Страховой полис'}
                    </Typography>

                    {(fileNames[type] || files[type]) ? (
                        <div style={{display: 'flex', gap: '16px', justifyContent: 'space-between'}}>
                            <Typography className={styles.fileName}>
                                <span>{fileNames[type] || files[type]?.name}</span>
                                <Button onClick={() => handleDeleteFile(type)}>Удалить</Button>
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Срок истечения документа"
                                    value={dates[type] ? dayjs(dates[type], 'DD.MM.YYYY') : null}
                                    onChange={(date) => handleDateChange(date, type)}
                                />
                            </LocalizationProvider>
                        <Box>
                        </Box>
                        </div>
                    ) : (
                        <>
                            <input
                                type="file"
                                id={`${type}-upload`}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFileChange(e, type)}
                            />
                            <Button component="label" htmlFor={`${type}-upload`} variant="contained" color="primary">
                                Загрузить файл
                            </Button>
                        </>
                    )}
                </Box>
            ))}

            {/* Action Buttons */}
            <Box className={styles.buttons}>
                <Button variant="contained" color="secondary" onClick={handleBack}>
                    НАЗАД
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    disabled={saveButtonDisabled}
                >
                    СОХРАНИТЬ ФАЙЛЫ
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                        await handleGatheredData();
                        handleNext();
                    }}
                    disabled={submitButtonDisabled}
                >
                    ОТПРАВИТЬ
                </Button>
            </Box>
        </Box>
    );
};

export default FormStep3;
