import API from "./api";
import {IMaintenance} from "../pages/MaintenancePage/MaintenancePage";

export interface IPostDate {
    date?: string;
    vehicle_id: number | null;
    id?: number;
    type: string;
    status?: string;
}

export class MaintenanceApi extends API {
    getMaintenances(): Promise<IMaintenance[]> {
        return this.get(`api/maintenances/all`);
    }

    postDate(data: any): Promise<any> {
        return this.post('api/maintenance', data);
    }
    async getExcel(periods: string[]): Promise<Blob> {
        const queryParams = periods?.map(period => `periods=${encodeURIComponent(period)}`).join('&');
        const response = await this.getBlob(`api/maintenances/report?${queryParams}`);
        return response.data;
    }
    getChecklist(id: number){
        return this.getBlob(`api/maintenances/${id}/maintenance-checklist`)
    }
    finishMaintenance(maintenanceId: string) {
        return this.post(`api/maintenances/${maintenanceId}/complete`)
    }
    getMaintenanceById(maintenanceId: string): Promise<any> {
        return this.get(`api/maintenances/${maintenanceId}`);
    }
    downloadMaintenanceReport(maintenanceId: number) {
        return this.getBlob(`api/maintenance/${maintenanceId}/report`)
    }
    downloadMaintenanceReports(vehicle_id: number, start_date: string, end_date: string) {
        return this.getBlob(`api/maintenance/reports?vehicle_id=${vehicle_id}&start_date=${start_date}&end_date=${end_date}`);
    }
}
