import React, {useEffect} from "react";
import { useForm, Controller } from "react-hook-form";
import {
    Modal,
    Box,
    Typography,
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from "@mui/material";
import {IVehicle} from "../../../api/authApi";

interface EditPartModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (partData: any) => void;
    initialData?: any;
    mode: "create" | "edit";
    vehicles: IVehicle[];
}

const units = ["шт", "услуга", "комплект", "кг", "л", "м", "м2", "м3"];

const EditPartModal: React.FC<EditPartModalProps> = ({
                                                         open,
                                                         onClose,
                                                         onSave,
                                                         initialData,
                                                         mode,
                                                         vehicles,
                                                     }) => {

    const {
        handleSubmit,
        control,
        reset,
    } = useForm();

    useEffect(() => {
        if (open) {
            reset({
                name: initialData?.name || "",
                description: initialData?.description || "",
                quantity: initialData?.quantity || 1,
                unit: initialData?.unit || "шт.",
                vehicle_id: initialData?.vehicle?.id || "",
                expense_code: initialData?.expense_code || "",
                comments: initialData?.comments || "",
            });
        }
    }, [open, initialData, reset]);

    const handleSave = (data: any) => {
        onSave({ ...initialData, ...data });
        reset();
        onClose();
    };

    return (
        <Modal open={open} onClose={() => {reset(); onClose();}}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 600,
                    backgroundColor: "background.paper",
                    p: 3,
                    borderRadius: 2,
                }}
            >
                <Typography variant="h6" mb={2}>
                    {mode === "edit" ? "Редактировать часть заявки" : "Добавить новую часть заявки"}
                </Typography>

                <form onSubmit={handleSubmit(handleSave)}>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                            <TextField fullWidth label="Название" margin="normal" {...field} />
                        )}
                    />

                    <Controller
                        name="description"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                fullWidth
                                label="Описание"
                                multiline
                                margin="normal"
                                {...field}
                            />
                        )}
                    />

                    <Controller
                        name="comments"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                fullWidth
                                label="Комментарии"
                                multiline
                                margin="normal"
                                {...field}
                            />
                        )} 
                    />

                    <Controller
                        name="quantity"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                fullWidth
                                label="Количество"
                                type="number"
                                margin="normal"
                                {...field}
                            />
                        )}
                    />

                    <Controller
                        name="unit"
                        control={control}
                        render={({ field }) => (
                            <FormControl fullWidth margin="normal">
                                <InputLabel id={'unitId'}>Единица измерения</InputLabel>
                                <Select {...field} id={'unitId'} label={'Единица измерения'}>
                                    {units.map((unit) => (
                                        <MenuItem key={unit} value={unit}>
                                            {unit}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="vehicle_id"
                        control={control}
                        render={({ field }) => (
                            <FormControl fullWidth margin="normal">
                                <InputLabel id={'vehicleId'}>Транспорт</InputLabel>
                                <Select {...field} id={'vehicleId'} label={'Транспорт'}>
                                    {vehicles.map((vehicle) => (
                                        <MenuItem key={vehicle.id} value={vehicle.id}>
                                            {vehicle.garage_number} - {vehicle.marka} {vehicle.model}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="expense_code"
                        control={control}
                        render={({ field }) => (
                            <TextField fullWidth label="Код затрат" margin="normal" {...field} />
                        )}
                    />

                    <Box display="flex" justifyContent="space-between" mt={3}>
                        <Button variant="outlined" onClick={onClose}>
                            Отмена
                        </Button>
                        <Button type="submit" variant="contained">
                            {mode === "edit" ? "Сохранить" : "Создать"}
                        </Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
};

export default EditPartModal;
