import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import React from "react";

interface ICloseMaintenance {
    open: boolean;
    onConfirm: () => void;
    onClose: () => void;
    text: string;
}

const ClosingMaintenanceConfirmationModal: React.FC<ICloseMaintenance> = ({ open, onClose, onConfirm, text }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <DialogContentText>
                    Вы действительно хотите {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Нет
                </Button>
                <Button onClick={onConfirm} color="secondary">
                    Да
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ClosingMaintenanceConfirmationModal;
