import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Box, Button, MenuItem, Modal, TextField, Typography } from "@mui/material";
import { IProjects } from "../../../api/registerApi";

interface CreateApplicationModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (applicationData: {
        id?: number;
        project_id: number;
        title: string;
        description: string;
        initiator_fullname: string;
        initiator_position: string;
        initiator_phone: string;
        project_code: string;
        priority: string;
    }) => void;
    projects: IProjects[];
    initialData?: any;
}

const CreateApplicationModal: React.FC<CreateApplicationModalProps> = ({
                                                                           open,
                                                                           onClose,
                                                                           onSave,
                                                                           projects,
                                                                           initialData,
                                                                       }) => {
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            project_id: projects[0]?.id || 0,
            title: "",
            description: "",
            initiator_fullname: "",
            initiator_position: "",
            initiator_phone: "",
            project_code: "",
            priority: "Не срочная",
        },
    });

    useEffect(() => {
        if (open) {
            reset(
                initialData?.id
                    ? { ...initialData }
                    : {
                        project_id: projects[0]?.id || 0,
                        title: "",
                        description: "",
                        initiator_fullname: "",
                        initiator_position: "",
                        initiator_phone: "",
                        project_code: "",
                        priority: "Не срочная",
                    }
            );
        }
    }, [open, initialData, projects, reset]);

    const onSubmit = (data: any) => {
        onSave(initialData?.id ? { ...data, id: initialData.id } : data);
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "66%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    borderRadius: 4,
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography variant="h6" mb={2}>
                    {initialData?.id ? "Редактировать заявку" : "Создать заявку"}
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <Controller
                            name="project_id"
                            control={control}
                            rules={{ required: "Выберите проект" }}
                            render={({ field }) => (
                                <TextField select label="Проект" {...field} fullWidth>
                                    {projects.map((project) => (
                                        <MenuItem key={project.id} value={project.id}>
                                            {project.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        />

                        <Controller
                            name="title"
                            control={control}
                            rules={{ required: "Введите название" }}
                            render={({ field }) => (
                                <TextField label="Название" {...field} fullWidth required error={!!errors.title} />
                            )}
                        />

                        <Controller
                            name="description"
                            control={control}
                            render={({ field }) => (
                                <TextField label="Описание" {...field} fullWidth multiline rows={3} />
                            )}
                        />

                        <Controller
                            name="initiator_fullname"
                            control={control}
                            rules={{ required: "Введите ФИО инициатора" }}
                            render={({ field }) => (
                                <TextField label="ФИО инициатора" {...field} fullWidth required />
                            )}
                        />

                        <Controller
                            name="initiator_position"
                            control={control}
                            render={({ field }) => (
                                <TextField label="Должность инициатора" {...field} fullWidth />
                            )}
                        />

                        <Controller
                            name="initiator_phone"
                            control={control}
                            rules={{ required: "Введите номер телефона" }}
                            render={({ field }) => (
                                <TextField label="Номер телефона" {...field} fullWidth required />
                            )}
                        />

                        <Controller
                            name="project_code"
                            control={control}
                            render={({ field }) => (
                                <TextField label="Код проекта" {...field} fullWidth />
                            )}
                        />

                        <Controller
                            name="priority"
                            control={control}
                            render={({ field }) => (
                                <TextField select label="Приоритет" {...field} fullWidth>
                                    <MenuItem value="Срочная">Срочная</MenuItem>
                                    <MenuItem value="Не срочная">Не срочная</MenuItem>
                                </TextField>
                            )}
                        />

                        <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
                            <Button onClick={onClose} color="secondary" variant="outlined">
                                Отмена
                            </Button>
                            <Button type="submit" color="primary" variant="contained">
                                Сохранить
                            </Button>
                        </Box>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
};

export default CreateApplicationModal;