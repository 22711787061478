import React, { useMemo, useState } from "react";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import dayjs from "dayjs";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { InventoryApi, IUtmEquipments } from "../../../../../api/inventoryApi";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";

interface EquipmentHistoryProps {
    equipment: IUtmEquipments;
    onBack: () => void;
    isFuelHistory?: boolean;
}

type HistoryItem = {
    type: "Arrival" | "Dispatch";
    date: string;
    quantity: number;
    note: string;
    provider?: string;
    vehicleName?: string;
    id: number;
};

const EquipmentHistory: React.FC<EquipmentHistoryProps> = ({
                                                               equipment,
                                                               onBack,
                                                               isFuelHistory,
                                                           }) => {
    const inventoryApi = new InventoryApi();
    const [editingNoteId, setEditingNoteId] = useState<number | null>(null);
    const [noteText, setNoteText] = useState<string>("");

    // Combine arrivals and dispatches into one array and sort by date
    const history: HistoryItem[] = [
        ...equipment.arrivals.map((arrival) => ({
            type: "Arrival" as const,
            id: arrival.id,
            date: dayjs(arrival?.arrival_date).format("DD.MM.YYYY"),
            quantity: arrival.quantity,
            note: arrival.note,
            provider: arrival.provider,
        })),
        ...equipment.dispatches.map((dispatch) => ({
            type: "Dispatch" as const,
            date: dayjs(dispatch?.dispatch_date).format("DD.MM.YYYY"),
            quantity: dispatch.quantity,
            id: dispatch.id,
            note: dispatch.note || "",
            vehicleName: dispatch.vehicle?.full_name,
        })),
    ].sort((a, b) => dayjs(b.date, "DD.MM.YYYY").diff(dayjs(a.date, "DD.MM.YYYY")));

    const handleSaveNote = async (arrivalId: number) => {
        try {
            await inventoryApi.updateInventoryArrival({ id: arrivalId, note: noteText });
            const updatedArrivals = equipment.arrivals.map((arrival) =>
                arrival.id === arrivalId ? { ...arrival, note: noteText } : arrival
            );
            equipment.arrivals = updatedArrivals;
            setEditingNoteId(null);
            setNoteText("");
        } catch (error) {
            console.error("Failed to update note:", error);
        }
    };

    const NoteCellRenderer = (props: any) => {
        const { value, data } = props;
        const isEditing = editingNoteId === data.id;

        return isEditing ? (
            <Box display="flex" alignItems="center" gap={1} width="100%">
                <TextField
                    value={noteText}
                    onChange={(e) => setNoteText(e.target.value)}
                    size="small"
                    fullWidth
                />
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() => handleSaveNote(data.id)}
                >
                    <CheckIcon />
                </IconButton>
            </Box>
        ) : (
            <Box display="flex" alignItems="center" gap={1} width="100%" justifyContent="space-between">
                <Typography>{value}</Typography>
                <IconButton
                    color="default"
                    size="small"
                    onClick={() => {
                        setEditingNoteId(data.id);
                        setNoteText(value || "");
                    }}
                >
                    <EditIcon />
                </IconButton>
            </Box>
        );
    };

    // Define column definitions
    const columnDefs: ColDef[] = [
        {
            field: "type",
            headerName: "Тип",
            filter: true,
            minWidth: 120,
            cellRenderer: (params: any) => {
                const bgColor = params.value === "Arrival" ? "lightgreen" : "lightcoral";
                return (
                    <Box
                        sx={{
                            backgroundColor: bgColor,
                            color: "black",
                            fontWeight: "bold",
                            textAlign: "center",
                            borderRadius: "4px",
                            padding: "4px",
                        }}
                    >
                        {params.value === "Arrival" ? "Приход" : "Выдача"}
                    </Box>
                );
            },
        },
        {
            field: "date",
            headerName: "Дата",
            minWidth: 300,
            
            filter: "agDateColumnFilter",
        },
        {
            field: "quantity",
            headerName: "Количество",
            minWidth: 250,
            filter: "agNumberColumnFilter",
        },
        {
            field: "provider",
            headerName: "Поставщик",
            minWidth: 300,
            filter: "agColumnFilter",
        },
        {
            field: "note",
            headerName: "Примечание",
            minWidth: 400,
            cellRenderer: NoteCellRenderer,
        },
        ...(isFuelHistory
            ? [
                {
                    field: "vehicleName",
                    headerName: "Выдано на ТС",
                    minWidth: 300,
                    filter: "agTextColumnFilter",
                },
            ]
            : []),
    ];


    // Default column properties
    const defaultColDef = useMemo(() => {
        return {
            sortable: true,
            filter: true,
            resizable: true,
        };
    }, []);

    return (
        <Box>
            <Box display={"flex"} gap={2} alignItems={"center"} mb={2}>
                <Button variant="outlined" onClick={onBack}>
                    Назад
                </Button>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                        sx={{
                            width: 16,
                            height: 16,
                            backgroundColor: "lightgreen",
                            marginRight: 0.5,
                        }}
                    />
                    <Typography variant="body2">Приход</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                        sx={{
                            width: 16,
                            height: 16,
                            backgroundColor: "lightcoral",
                            marginRight: 0.5,
                        }}
                    />
                    <Typography variant="body2">Выдача</Typography>
                </Box>
                <Box>Название : <strong>{equipment.name}</strong></Box>
            </Box>
            <Box className="ag-theme-alpine" style={{ height: 700, width: "100%" }}>
                <AgGridReact
                    rowData={history}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    pagination={true}
                    paginationPageSize={10}
                />
            </Box>
        </Box>
    );
};

export default EquipmentHistory;
