import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

interface RefundConfirmationModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

const RefundConfirmationModal: React.FC<RefundConfirmationModalProps> = ({ open, onClose, onConfirm }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{ padding: '16px', backgroundColor: 'white', borderRadius: 2, maxWidth: 400, margin: 'auto', marginTop: '20%' }}>
                <Typography variant="h6" gutterBottom>
                    Вы уверены, что хотите вернуть этот товар на склад?
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <Button onClick={onClose} variant="outlined">
                        Отменить
                    </Button>
                    <Button onClick={onConfirm} variant="contained" color="primary">
                        Да, вернуть
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default RefundConfirmationModal;
