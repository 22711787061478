import React, { useEffect, useState } from "react";
import {
    Modal,
    Box,
    Typography,
    TextField,
    Button,
    MenuItem,
} from "@mui/material";
import { FuelItem } from "./FuelAccounting";

interface IFuelVehicles {
    id: number;
    full_name: string;
    drivers: { id: number; full_name: string }[];
}

interface FuelDispatchModalProps {
    open: boolean;
    onClose: () => void;
    fuelItem: FuelItem | null;
    onSubmit: (data: any) => void;
}

const FuelDispatchModal: React.FC<FuelDispatchModalProps> = ({
                                                                 open,
                                                                 onClose,
                                                                 onSubmit,
                                                                 fuelItem,
                                                             }) => {
    const [formData, setFormData] = useState({
        vehicle_id: "",
        driver_id: "",
        quantity: "",
    });

    const [selectedVehicle, setSelectedVehicle] = useState<IFuelVehicles | null>(null);

    const handleVehicleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const vehicleId = Number(event.target.value);
        const vehicle = fuelItem?.vehicles?.find((v) => v.id === vehicleId);

        if (vehicle) {
            setSelectedVehicle({
                ...vehicle,
                drivers: Array.isArray(vehicle.drivers) ? vehicle.drivers : [vehicle.drivers], // Normalize drivers
            });
            setFormData((prev) => ({
                ...prev,
                vehicle_id: vehicleId.toString(),
                driver_id: "", // Reset driver when vehicle changes
            }));
        }
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFormSubmit = () => {
        if (!formData.driver_id) {

            return;
        }
        const submissionData = {
            dispatch_type: "gsm",
            inventory_id: fuelItem?.id,
            quantity: formData.quantity,
            vehicle_id: formData.vehicle_id,
            driver_id: formData.driver_id,
            status: 'approved'
        };
        console.log("Submission Data:", submissionData); // Debugging
        onSubmit(submissionData);
        onClose();
    };

    const resetForm = () => {
        setFormData({
            vehicle_id: "",
            driver_id: "",
            quantity: "",
        });
        setSelectedVehicle(null);
    };

    useEffect(() => {
        if (!open) {
            resetForm();
        }
    }, [open]);

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Выдача топлива
                </Typography>
                {/* Vehicle Names */}
                <TextField
                    fullWidth
                    select
                    label="Выбрать ТС"
                    name="vehicle_id"
                    value={formData.vehicle_id}
                    onChange={handleVehicleChange}
                    margin="normal"
                >
                    {fuelItem?.vehicles?.length ? (
                        fuelItem.vehicles.map((vehicle) => {
                            return (
                                <MenuItem key={vehicle.id} value={vehicle.id}>
                                    {vehicle.full_name}
                                </MenuItem>
                            );
                        })
                    ) : (
                        <MenuItem disabled>Нет доступных ТС</MenuItem>
                    )}
                </TextField>
                {/* Driver/Operator */}
                <TextField
                    fullWidth
                    select
                    label="Выбрать Водителя"
                    name="driver_id"
                    value={formData.driver_id}
                    onChange={handleInputChange}
                    margin="normal"
                    error={!formData.driver_id && formData.vehicle_id !== ""}
                    helperText={!formData.driver_id && formData.vehicle_id !== "" ? "Водитель обязателен для выбора" : ""}
                >
                    {selectedVehicle?.drivers.length ? (
                        selectedVehicle.drivers.map((driver) => (
                            <MenuItem key={driver.id} value={driver.id}>
                                {driver.full_name}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled>Нет доступных водителей</MenuItem>
                    )}
                </TextField>
                {/* Dispatch Quantity */}
                <TextField
                    fullWidth
                    label="Выдать литров"
                    name="quantity"
                    type="number"
                    value={formData.quantity}
                    onChange={handleInputChange}
                    margin="normal"
                />
                {/* Current Quantity */}
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: "right",
                        fontWeight: "bold",
                        margin: "16px 0",
                    }}
                >
                    Актуальный остаток: {fuelItem?.quantity || 0} л.
                </Typography>
                {/* Submit Button */}
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFormSubmit}
                    >
                        Выдать
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default FuelDispatchModal;
