import React, { useEffect, useState } from "react";
import {Box, Typography, Button, Divider, IconButton} from "@mui/material";
import {IArrivals, IDispatches, InventoryApi, IUtmEquipments} from "../../../../api/inventoryApi";
import useModal from "../../../VehiclesPage/modals/useModal";
import FuelAddModal from "./FuelAddModal";
import FuelDispatchModal from "./FuelDispatchModal";
import styles from "../../InventoryNew.module.scss";
import HistoryIcon from "@mui/icons-material/History";
import EquipmentHistory from "../Equipment/components/EquipmentHistory";

export interface IFuelVehicles {
    id: number;
    full_name: string;
    drivers: {id: number, full_name: string}[];
    tank_volume: number;
}

export interface FuelItem {
    code?: string;
    id: number;
    group_id: number;
    group_name: string;
    name: string;
    quantity: number;
    unit: string;
    last_arrival_date: string | null;
    last_dispatch_date: string | null;
    price: number;
    total_price: number;
    type: string;
    vehicle_names?: string[];
    vehicles?: IFuelVehicles[];
    arrivals: IArrivals[];
    dispatches: IDispatches[];
}

interface GroupedFuel {
    groupName: string;
    items: FuelItem[];
}

export interface IFuelGroup {
    created_at: string;
    description: string;
    id: number;
    name: string;
    updated_at: string;
}

const FuelAccounting: React.FC = () => {
    const [fuelGroups, setFuelGroups] = useState<GroupedFuel[]>([]);
    const [groups, setGroups] = useState<IFuelGroup[]>([]);
    const inventoryApi = new InventoryApi();
    const [vehicleNames, setVehicleNames] = useState<string[]>([]);
    const { isOpen, openModal, closeModal } = useModal();
    const [selectedFuelItem, setSelectedFuelItem] = useState<FuelItem | null>(null);
    const [selectedHistory, setSelectedHistory] = useState<FuelItem | null>(null);

    const handleGetFuelAccounting = async () => {
        const data: FuelItem[] = await inventoryApi.getInventoryEquipment("gsm");
        if(data) {
            const groupedData = groupByGroupName(data);
            setFuelGroups(groupedData);
        }
    };
    const fetchVehicleNames = async () => {
        const data = await inventoryApi.getVehicleNames();
        setVehicleNames(data);
    }

    const groupByGroupName = (items: FuelItem[]): GroupedFuel[] => {
        const grouped = items?.reduce<Record<string, FuelItem[]>>((acc, item) => {
            acc[item.group_name] = acc[item.group_name] || [];
            acc[item.group_name].push(item);
            return acc;
        }, {});

        return Object.entries(grouped)?.map(([groupName, items]) => ({
            groupName,
            items,
        }));
    };

    const handleGetFuelGroups = async () => {
        const data = await inventoryApi.getFuelGroup();
        setGroups(data);
    }

    const handleAddFuel = async (data: any) => {
        await inventoryApi.addInventoryArrival(data);
        void handleGetFuelAccounting();
    };

    const handleOpenModalWithFuel = (fuelItem: FuelItem) => {
        setSelectedFuelItem(fuelItem);
        openModal("addFuel");
    };

    const handleOpenDispatchModal = (fuelItem: FuelItem) => {
        setSelectedFuelItem(fuelItem);
        openModal("dispatchFuel");
    };

    const handleDispatchFuel = async (data: any) => {
        try {
            await inventoryApi.dispatchInventoryItem(data);
        } catch (e) {
            console.log(e)
        }
        void handleGetFuelAccounting();
    }

    useEffect(() => {
        void handleGetFuelAccounting();
        void fetchVehicleNames();
        void handleGetFuelGroups();
    }, []);


    return (
        <>
        <Box>
            {selectedHistory ?
                <EquipmentHistory
                    equipment={selectedHistory as IUtmEquipments}
                    onBack={() => setSelectedHistory(null)}
                    isFuelHistory={true}
                /> :
                <>
            <Button variant={'contained'} sx={{mb:1}} onClick={()=> openModal('addFuel')}>
                Добавить
            </Button>
            {fuelGroups?.map((group, index) => (
                <Box key={index} sx={{ marginBottom: "24px" }}>
                    <Typography variant="h6" gutterBottom>
                        {group.groupName}
                    </Typography>
                    <Divider sx={{ marginBottom: "16px" }} />
                    {group?.items?.map((item) => (
                        <Box
                            key={item.id}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginBottom: "8px",
                                padding: "8px",
                                border: "1px solid #e0e0e0",
                                borderRadius: "4px",
                                backgroundColor: "#f9f9f9",
                            }}
                        >
                            <Typography variant="body1">
                                Актуальный остаток <strong>{item.name}</strong>: {item.quantity} {item.unit}.
                            </Typography>
                            <Box sx={{display: "flex", alignItems: "center", gap: 2}}>
                                <IconButton className={styles.historyButton} onClick={() => setSelectedHistory(item)}>
                                    <HistoryIcon fontSize="small" />
                                </IconButton>
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => handleOpenModalWithFuel(item)}
                                >
                                    Приход
                                </Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => handleOpenDispatchModal(item)}
                                >
                                    Выдать
                                </Button>
                            </Box>
                        </Box>
                    ))}
                </Box>
            ))}
                </>
            }
        </Box>
            <FuelAddModal
                open={isOpen['addFuel']}
                onClose={() => {
                    closeModal('addFuel');
                    setSelectedFuelItem(null);
                }}
                groups={groups}
                vehicleNames={vehicleNames}
                selectedFuelItem={selectedFuelItem}
                onSubmit={handleAddFuel}
            />
            <FuelDispatchModal
                open={isOpen["dispatchFuel"]}
                onClose={() => {
                    closeModal("dispatchFuel");
                    setSelectedFuelItem(null);
                }}
                fuelItem={selectedFuelItem}
                onSubmit={(data) => handleDispatchFuel(data)}
            />
        </>
    );
};

export default FuelAccounting;
