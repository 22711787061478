import React from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {Box, List, ListItemText, ListItemIcon, Typography, Badge} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InventoryIcon from '@mui/icons-material/Inventory';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import NoteIcon from '@mui/icons-material/Note';
import { styled } from '@mui/material/styles';
import Cookies from "js-cookie";
import CarRepairIcon from '@mui/icons-material/CarRepair';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import {useAppContext} from "../store/AppContext";
import AssignmentIcon from '@mui/icons-material/Assignment';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import {ReactComponent as Logo} from "../../assets/logo_white.svg";

const NavItem = styled(NavLink)(({ theme }) => ({
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    width: '100%',
    '&.active': {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
    },
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: '#fff',
    },
}));

const Sidebar: React.FC = () => {
    const navigate = useNavigate();
    const {dispatchesCount, expiringCount} = useAppContext();
    const handleLogout = () => {
        Cookies.remove('access_token');
        Cookies.remove('refresh_token');
        navigate('/login');
    };
    return (
        <Box
            sx={{
                width: 240,
                height: '100vh',
                backgroundColor: '#2f3e46',
                color: '#fff',
                position: 'fixed',
            }}
        >
            <Box
                sx={{
                    p: 1,
                    mt:1,
                    maxWidth: '100%',
                    overflow: 'hidden',
                    textAlign: 'center',
                }}
            >
                <Logo
                    style={{
                        maxWidth: '100%',
                        height: 'auto',
                    }}
                />
            </Box>
            <List>
                <div style={{pointerEvents: 'none'}}>
                    <NavItem to="/home" className={({isActive}) => (isActive ? 'active' : '')}>
                        <ListItemIcon sx={{color: 'gray'}}>
                            <HomeIcon/>
                        </ListItemIcon>
                        <ListItemText sx={{color: 'gray'}} primary="Главная"/>
                    </NavItem>
                </div>
                <NavItem to="/vehicles" className={({isActive}) => (isActive ? 'active' : '')}>
                    <ListItemIcon sx={{color: '#fff'}}>
                        <DirectionsCarIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Подвижной состав"/>
                </NavItem>
                <NavItem to="/maintenance" className={({isActive}) => (isActive ? 'active' : '')}>
                    <ListItemIcon sx={{color: '#fff'}}>
                        <CalendarTodayIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Планирование ТО"/>
                </NavItem>
                <NavItem to="/inventory" className={({isActive}) => (isActive ? 'active' : '')}>
                    <ListItemIcon sx={{color: '#fff'}}>
                        <InventoryIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Badge
                                badgeContent={dispatchesCount > 0 ? dispatchesCount : null}
                                color="error"
                                sx={{
                                    '& .MuiBadge-badge': {
                                        right: -15,
                                        top: 10,
                                        padding: '0 4px',
                                        backgroundColor: '#ff5722',
                                        color: '#fff',
                                        fontSize: '0.75rem',
                                        minWidth: 20,
                                        height: 20,
                                        borderRadius: '50%',
                                    },
                                }}
                            >
                                Склад
                            </Badge>
                        }
                    />
                </NavItem>
                <NavItem to="/templates" className={({isActive}) => (isActive ? 'active' : '')}>
                    <ListItemIcon sx={{color: '#fff'}}>
                        <NoteIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Шаблоны расходников"/>
                </NavItem>
                <NavItem to="/vehicle_types" className={({isActive}) => (isActive ? 'active' : '')}>
                    <ListItemIcon sx={{color: '#fff'}}>
                        <CarRepairIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Типы ТС"/>
                </NavItem>
                <NavItem to="/drivers" className={({isActive}) => (isActive ? 'active' : '')}>
                    <ListItemIcon sx={{color: '#fff'}}>
                        <ContactPageIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Водители"/>
                </NavItem>
                <NavItem to="/stats" className={({isActive}) => (isActive ? 'active' : '')}>
                    <ListItemIcon sx={{color: '#fff'}}>
                        <QueryStatsIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Статистика"/>
                </NavItem>
                <NavItem to="/documents" className={({isActive}) => (isActive ? 'active' : '')}>
                    <ListItemIcon sx={{color: '#fff'}}>
                        <AssignmentIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Badge
                                badgeContent={expiringCount > 0 ? expiringCount : null}
                                color="error"
                                sx={{
                                    '& .MuiBadge-badge': {
                                        right: -15,
                                        top: 10,
                                        padding: '0 4px',
                                        backgroundColor: '#ff5722',
                                        color: '#fff',
                                        fontSize: '0.75rem',
                                        minWidth: 20,
                                        height: 20,
                                        borderRadius: '50%',
                                    },
                                }}
                            >
                                Документация
                            </Badge>
                        }
                    />
                </NavItem>
                <NavItem to="/register" className={({isActive}) => (isActive ? 'active' : '')}>
                    <ListItemIcon sx={{color: '#fff'}}>
                        <AppRegistrationIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Реестр заявок"/>
                </NavItem>
                <div onClick={handleLogout}>
                    <NavItem to='/'>
                    <ListItemIcon sx={{color: '#fff'}}>
                        <ExitToAppIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Выйти"/>
                    </NavItem>
                </div>
            </List>
        </Box>
    );
};

export default Sidebar;
