import React, {useEffect, useState} from "react";
import {
    Badge,
    Box,
    Button,
    IconButton,
    Paper,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tabs,
    Typography,
} from "@mui/material";
import {Attachment, Delete, Edit} from "@mui/icons-material";
import {IApplication, INotification, IProjects, RegisterApi} from "../../api/registerApi";
import useModal from "../VehiclesPage/modals/useModal";
import CreateApplicationModal from "./modals/CreateApplicationModal";
import DeleteConfirmationModal from "./modals/DeleteConfirmationModal";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useAppContext} from "../store/AppContext";
import TaskIcon from '@mui/icons-material/Task';
import ReplayIcon from '@mui/icons-material/Replay';
import RevertConfirmModal from "./modals/RevertConfirmModal";
import ProgressBar from "./ProgressBar";
import ConfirmSanctionModal from "./modals/ConfirmSanctionModal";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsModal from "./modals/NotificationModal";

const RegisterApplications = () => {
    const registerApi = new RegisterApi();
    const [applications, setApplications] = useState<IApplication[] | null>(null);
    const [selectedApplication, setSelectedApplication] = useState<IApplication | null>(null);
    const [projects, setProjects] = useState<IProjects[]>([]);
    const { isOpen, openModal, closeModal } = useModal();
    const {role, setRole} = useAppContext();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(0);
    const [notifications, setNotifications] = useState<INotification[]>([]);

    const handleTabChange = async (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
        await fetchApplications(newValue);
    };

    const fetchApplications = async (tabIndex: number) => {
        try {
            switch (tabIndex) {
                case 0: // "Все заявки"
                    await handleGetAllApplications();
                    break;
                case 1: // "Мои заявки"
                    await handleGetApplicationByRole();
                    break;
                case 2: // "Завершенные"
                    await handleGetFinishedApplications();
                    break;
                case 3: //На удаление
                    await handleGetApplicationForDelete();
                    break;
                default:
                    break;
            }
        } catch (e) {
            console.error("Failed to fetch applications:", e);
        }
    };

    const handleGetApplicationByRole = async () => {
        try {
            const data = await registerApi.getApplications(role as string);
            console.log(data);
            setApplications(data);
        } catch (e) {
            console.log(e);
        }
    };

    const handleGetApplicationForDelete = async () => {
        try {
            const data = await registerApi.getApplicationsForDelete();
            console.log(data);
            setApplications(data);
        } catch (e) {
            console.log(e);
        }
    }

    const handleGetAllApplications = async () => {
        try {
            const data = await registerApi.getAllApplications();
            setApplications(data);
        } catch (e) {
            console.log(e);
        }
    }
    const handleGetFinishedApplications = async () => {
        try {
            const data = await registerApi.getFinishedApplications();
            setApplications(data);
        } catch (e) {
            console.log(e);
        }
    }

    const handleGetProjects = async () => {
        try {
            const data = await registerApi.getProjects();
            setProjects(data);
        } catch (e) {
            console.log(e)
        }
    }

    const handleEditApplication = async (id: number) => {
        openModal('create');
        try {
            const data = await handleGetApplicationById(id);
            setSelectedApplication(data);
        } catch (e) {
            console.log(e);
        }
        // setSelectedApplication(data)
    };

    const handleDeleteApplication = async (id: number) => {
        try {
            await registerApi.deleteApplication(id, role as string);
            if(role!=='Проектный менеджер'){
                toast.success('Заявка успешно отправлена на удаление');
            } else toast.success('Заявка успешно удалена');
        } catch (e) {
            console.log(e);
        } finally {
            closeModal('delete');
        }
    };

    const handleGetApplicationById = async (id:number) => {
        const data = await registerApi.getApplicationById(id);
        setSelectedApplication(data);
        return data;
    }

    const handleSaveApplication = async (applicationData: any) => {
        try {
            if (applicationData.id) {
                await registerApi.putApplication(applicationData.id, applicationData);
                console.log("Заявка обновлена!");
            } else {
                await registerApi.postApplications(applicationData, role as string);
                toast.success("Заявка успешна создана!");
            }
            closeModal('create');
        } catch (error) {
            console.error("Ошибка:", error);
        } finally {
            void fetchApplications(activeTab);
        }
    };

    const handleSanctionConfirm = async (selectedRole: string, comment: string) => {
        try {
            await registerApi.sanctionApplication(selectedApplication?.id as number, role as string, comment, selectedRole);
            toast.success('Заявка успешно санкционирована!');
            void fetchApplications(activeTab);
        } catch (e:any) {
            toast.error(e.response.data.detail);
            console.log(e);
        }
    }

    const handleOpenModal = (app: IApplication, modalName: string) => {
            openModal(modalName);
            setSelectedApplication(app);
    }

    const handleRevertConfirm = async (selectedRole: string, comment: string) => {
        try {
            await registerApi.revertApplication(selectedApplication?.id as number, role as string, selectedRole, comment);
            toast.success('Заявка успешно возвращена!');
            void fetchApplications(activeTab);
        } catch (e:any) {
            toast.error(e.response.data.detail);
            console.log(e);
        }
    }
    const handleGetNotifications = async () => {
        try {
            const data = await registerApi.getNotifications(role as string);
            setNotifications(data);
        } catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        void fetchApplications(activeTab);
        void handleGetProjects();
        void handleGetNotifications();
    }, []);

    const unreadCount = notifications.filter((notification) => !notification.read).length;

    return (
        <Box>
            <Stack direction={'row'} justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    <Typography>{role}</Typography>
                    <IconButton onClick={() => openModal('notifications')}>
                        <Badge badgeContent={unreadCount} color="error">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <Button onClick={() => setRole(null)}>Сменить роль</Button>
                </Box>
                {role==='Инициатор' &&
                    <Button variant="contained" onClick={() => openModal('create')} sx={{p:1, mb:2}}>
			                Создать заявку
                    </Button>
                }
            </Stack>
            <Box mb={2}>
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab label="Все заявки" />
                    <Tab label="Мои заявки" />
                    <Tab label="Завершенные" />
                    {role === "Проектный менеджер" && <Tab label="На удаление" />}
                </Tabs>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Название</TableCell>
                            <TableCell>Инициатор</TableCell>
                            <TableCell>Этап</TableCell>
                            <TableCell>Статус</TableCell>
                            <TableCell>Дата создания</TableCell>
                            <TableCell>Комментарии</TableCell>
                            <TableCell>Проект</TableCell>
                            <TableCell>Действия</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {applications?.length !== 0 ? applications?.map((app) => (
                            <>
                                <TableRow key={app.id} onClick={() => navigate(`/register/${app.id}`)} sx={{cursor: 'pointer'}} hover>
                                    <TableCell align={'left'}>
                                        <Stack direction="row" alignItems="left">
                                            {app.priority === 'Срочная' && <PriorityHighIcon color={'warning'}/>}
                                            <Typography>{app.id} </Typography>
                                        </Stack>
                                    </TableCell>
                                    <TableCell>{app.title}</TableCell>
                                    <TableCell>{app.initiator_fullname}</TableCell>
                                    <TableCell>
                                       <ProgressBar stage={app.stage}/>
                                    </TableCell>
                                    <TableCell>{app.status}</TableCell>
                                    <TableCell>
                                        {new Date(app.created_at).toLocaleDateString()}
                                    </TableCell>
                                    <TableCell>{app.comments}</TableCell>
                                    <TableCell>{app.project_name}</TableCell>
                                    <TableCell>
                                        <Box display="flex">
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                onClick={(e) => {
                                                    handleOpenModal(app, 'revert');
                                                    e.stopPropagation();
                                                }}
                                            >
                                                <ReplayIcon />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                onClick={(e) => {
                                                    handleOpenModal(app, 'sanction');
                                                    e.stopPropagation();
                                                }}
                                            >
                                                <TaskIcon />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                onClick={(e) => {
                                                    handleEditApplication(app.id);
                                                    e.stopPropagation();
                                                }}
                                            >
                                                <Edit />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                color="error"
                                                onClick={(e) => {
                                                    openModal('delete');
                                                    setSelectedApplication(app);
                                                    e.stopPropagation();
                                                }}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </>
                        )) : <Typography p={2} color="text.secondary">Нет заявок</Typography>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <CreateApplicationModal
                open={isOpen['create']}
                onClose={() => {
                    closeModal('create');
                    setSelectedApplication(null);
                }}
                onSave={handleSaveApplication}
                projects={projects}
                initialData={selectedApplication}
            />
            <DeleteConfirmationModal open={isOpen['delete']}
                                     onClose={()=> {
                                         closeModal('delete');
                                         setSelectedApplication(null)
                                     }}
                                     onDelete={() => handleDeleteApplication(selectedApplication?.id as number)}
            />
            <RevertConfirmModal open={isOpen['revert']}
                                onClose={() => {
                                        closeModal('revert');
                                        setSelectedApplication(null)
                                }}
                                onConfirm={handleRevertConfirm}
            />
            <ConfirmSanctionModal
                open={isOpen['sanction']}
                onClose={() => {
                    closeModal('sanction');
                    setSelectedApplication(null);
                }}
                onConfirm={handleSanctionConfirm}
                isPurchasing={role === 'Отдел закупок'}
            />
            <NotificationsModal
                open={isOpen['notifications']}
                onClose={() => {closeModal('notifications')}}
                notifications={notifications}
            />
        </Box>
    );
};

export default RegisterApplications;
