import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, Checkbox, Container, IconButton, TextField, Typography} from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {ArrowBack as ArrowBackIcon} from '@mui/icons-material';
import {Field, FieldArray, Form, Formik} from 'formik';
import {useNavigate, useParams} from 'react-router-dom';
import dayjs from 'dayjs';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {ReactComponent as Logo} from './assets/logo.svg';
import {VehiclesApi} from "../../../api/vehiclesApi";
import {IVehicle} from "../../../api/authApi";
import {DriversApi} from "../../../api/driversApi";
import InstallationActModal, {IInstallForm} from "./modals/InstallationActModal";
import useModal from "../modals/useModal";
import DefectActModal, {IDefectReportForm} from "./modals/DefectActModal";
import {RepairApi} from "../../../api/repairApi";
import {useFileDownload} from "../../../hooks/useFileDownload";
import ClosingMaintenanceConfirmationModal from "./modals/ClosingMaintenanceConfirmationModal";
import {toast} from "react-toastify";

// Define form data type
export interface IWorkItem {
    name: string;
    comments: string;
    id?: number;
    status?: string;
    is_approved?: boolean;
}
export interface IWorker{
    full_name: string;
    position: string;
}
export interface IFormData {
    customer_department: string;
    executor_department: string;
    start_date: string | null;
    end_date: string | null;
    malfunction_description: string;
    work_items: IWorkItem[];
    card_opened_by: string;
    involved_workers: IWorker[];
    commission_members: IWorker[];
    commission_conclusion: string;
}

function VehicleRepairForm() {
    const navigate = useNavigate();
    const {id, repairId} = useParams();
    const vehicleApi = new VehiclesApi();
    const driversApi = new DriversApi();
    const repairApi = new RepairApi();
    const [vehicle, setVehicle] = useState<IVehicle>();
    const [selectedDriver, setSelectedDriver] = useState<string>('');
    const { isOpen, openModal, closeModal } = useModal();
    const [openedAct, setOpenedAct] = useState<string>('')
    const initialInstallItems: IInstallForm = {
        city: '',
        application_date: '',
        installation_items: [{ inventory_item_id: '', unit: '', quantity: '', comments: ''}]
    };

    const initialDefectForm: IDefectReportForm = {
        city: '',
        department: '',
        application_date: '',
        defect_description: '',
        defective_part: '',
        defect_cause: '',
        defect_fix: '',
        application_execution_date: '',
        defect_fixing_date: '',
        application_number: '',
        utilization_report: '',
    };
    const [installItems, setInstallItems] = useState<IInstallForm>(initialInstallItems);
    const [defectForm, setDefectForm] = useState<IDefectReportForm>(initialDefectForm)
    const { downloadFile, loading, error } = useFileDownload();
    const handleGetVehicleInfo = async () => {
        try {
            const data = await vehicleApi.getVehicleById(Number(id));
            setVehicle(data);
        } catch (e) {
            console.log(e);
        }
    }
    const handleGetVehicleDriver = async () => {
        try {
            const response = await driversApi.getActiveDriverByVehicle(Number(id));
            setSelectedDriver(response[0].driver_name);
        } catch (error) {
            console.error('Error fetching vehicle driver:', error);
        }
    };
    useEffect(() => {
        void handleGetVehicleInfo();
        void handleGetVehicleDriver();
    }, []);


    // Initial values
    const [initialValues, setInitialValues] = useState<IFormData>({
        customer_department: '',
        executor_department: '',
        start_date: '',
        end_date: '',
        malfunction_description: '',
        work_items: [{ name: '', comments: '' }],
        card_opened_by: '',
        involved_workers: [{ full_name: '', position: '' }],
        commission_members: [{ full_name: '', position: '' }],
        commission_conclusion: ''
    });
    const handleSubmit = async (values: IFormData):Promise<IFormData> => {
        const data = await repairApi.saveRepair(repairId as string, values);
        return data;
    };
    const resetInstallItems = () => setInstallItems(initialInstallItems);
    const resetDefectForm = () => setDefectForm(initialDefectForm);
    const handleOpenModal = useCallback( async (values: IFormData, type: string, index:number) => {
        if (type === 'install') {
            resetInstallItems();
        } else {
            resetDefectForm();
        }
        openModal(type);
        try {
            const response = await handleSubmit(values);
            if(type === 'install') {
                const data = await repairApi.getInstallationReport(response.work_items[index]?.id as number);
                setInstallItems(data);
            } else {
                const data = await repairApi.getDefectReport(response.work_items[index]?.id as number);
                setDefectForm(data);
            }
        } catch (e) {
            console.log(e)
        }
        finally {
            handleGetRepairForm();
        }
    },[handleSubmit, openModal, repairApi])

    const handleFinalSubmit = async (values: IFormData) => {
        if(!values.commission_conclusion || values.commission_conclusion.trim() === '') {
            closeModal('confirm');
            toast.error('Поле заключение комиссии обязательно к заполнению!');
            return;
        }
        try {
            await repairApi.finishRepair(repairId as string,values);
            navigate(`/vehicle-info/${id}`)
        } catch (e: any) {
            closeModal('confirm');
            console.log(e)
            toast.error(e.response.data.detail)
        }
    }
    const handleReinstatement = async (values: IFormData) => {
        try {
            console.log(values)
            await repairApi.reinstatementRepair(id as string, values);
            navigate(`/vehicle-info/${id}`)
        } catch (e) {
            console.log(e);
        }
    }

    const handleGetRepairForm = async () => {
        const data = await repairApi.getRepair(repairId as string);
        data.work_items = data.work_items?.length ? data.work_items : [{ name: '', comments: '' }];
        data.involved_workers = data.involved_workers?.length ? data.involved_workers : [{ full_name: '', position: '' }];
        data.commission_members = data.commission_members?.length ? data.commission_members : [{ full_name: '', position: '' }];
        setInitialValues(data);
    }
    useEffect(() => {
        void handleGetRepairForm();
    }, []);

    const handleDownload = () => {
        downloadFile({
            apiCall: () => repairApi.downloadRepairDoc(repairId as string),
        });
    };



    return (
        <>
            {/* Back Button */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <IconButton onClick={() => navigate(-1)} sx={{}}>
                    <ArrowBackIcon />
                </IconButton>
            </Box>

            {/* Main Container */}
            <Container maxWidth={false} sx={{width: '100%', backgroundColor: '#F5F5F5', border: '1px solid black', borderRadius: '6px', mt: 2 }}>
                {/* Header Section */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        py: 2,
                        px: 2,
                        borderRadius: 1,
                        position: 'relative',
                    }}
                >
                    <Logo/>
                    <Typography
                        variant="h5"
                        sx={{
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            textAlign: 'center',
                        }}
                    >
                        НАРЯД - ЗАДАНИЕ № {repairId}
                    </Typography>
                </Box>

                {/* Form with Formik */}
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                >
                    {({ values, errors, touched, handleChange, setFieldValue }) => (
                        <>
                        <Form>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: '1px solid #c4c4c4', p: 2,}}>
                                {/* Left Side (Заказчик and Дата постановки на ремонт) */}
                                <Box sx={{ flex: 1, pr: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Typography variant="h6" sx={{fontWeight: 'bold'}}>Заказчик:</Typography>
                                        <Field
                                            as={TextField}
                                            size={'small'}
                                            name="customer_department"
                                            value={values.customer_department}
                                            onChange={handleChange}
                                            sx={{p: 2}}
                                        />
                                    </Box>
                                    <Typography variant="h6" gutterBottom sx={{fontWeight: 'bold'}}>Дата постановки на ремонт:</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={values.start_date ? dayjs(values.start_date) : null}
                                            onChange={(newValue) => setFieldValue('start_date', newValue ? newValue.format('YYYY-MM-DD') : null)}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                {/* Right Side (Исполнитель and Дата выхода из ремонта) */}
                                <Box sx={{ flex: 1, pl: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Typography variant="h6" sx={{fontWeight: 'bold'}}>Исполнитель:</Typography>
                                        <Field
                                            size={'small'}
                                            as={TextField}
                                            name="executor_department"
                                            value={values.executor_department}
                                            onChange={handleChange}
                                            sx={{p: 2}}
                                        />
                                    </Box>
                                    <Typography variant="h6" gutterBottom sx={{fontWeight: 'bold'}}>Дата выхода из ремонта:</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={values.end_date ? dayjs(values.end_date) : null}
                                            onChange={(newValue) => setFieldValue('end_date', newValue ? newValue.format('YYYY-MM-DD') : null)}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Box>


                            {/*next part*/}
                            <Box sx={{border: '1px solid #c4c4c4', p: 2, mt: 1}}>
                                {/* Left Label */}
                                <Box sx={{display: 'flex', alignItems: 'center', gap: '16px'}}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                        На производство ремонта ТС:
                                    </Typography>
                                    <div style={{display: 'flex', gap: '32px'}}>
                                        {/* Марка */}
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <Typography variant="body1" fontWeight={'bold'}>Марка:</Typography>
                                            <Field
                                                size={'small'}
                                                as={TextField}
                                                name="marka"
                                                value={vehicle?.marka}
                                                onChange={handleChange}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                sx={{gridColumn: 'span 1'}}
                                            />
                                            {/* Водитель */}
                                            <Typography variant="body1" fontWeight={'bold'}>Водитель:</Typography>
                                            <Field
                                                size={'small'}
                                                as={TextField}
                                                name="driver"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                value={selectedDriver}
                                                onChange={handleChange}
                                                sx={{gridColumn: 'span 1'}}
                                            />
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            {/* ГРНЗ */}
                                            <Typography variant="body1" fontWeight={'bold'}>ГРНЗ:</Typography>
                                            <Field
                                                size={'small'}
                                                as={TextField}
                                                name="gos_number"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                value={vehicle?.gos_number}
                                                onChange={handleChange}
                                                sx={{gridColumn: 'span 1'}}
                                            />
                                            {/* Пробег */}
                                            <Typography variant="body1" fontWeight={'bold'}>Пробег:</Typography>
                                            <Field
                                                size={'small'}
                                                as={TextField}
                                                name="mileage"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                value={vehicle?.default_tracking_type === 'Пробег' ? String(vehicle?.mileage) : String(vehicle?.moto_hours)}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div>
                                            {/* VIN */}
                                            <Typography variant="body1" fontWeight={'bold'}>VIN:</Typography>
                                            <Field
                                                size={'small'}
                                                as={TextField}
                                                name="serial_number"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                value={vehicle?.serial_number}
                                                onChange={handleChange}
                                                sx={{gridColumn: 'span 1'}}
                                            />
                                        </div>
                                        </div>
                                </Box>
                            </Box>
                            {/*next part*/}
                            <Box sx={{ border: '1px solid #c4c4c4', p: 2, mt: 1, display: 'flex', gap: '16px', alignItems: 'center' }}>
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    sx={{ whiteSpace: 'nowrap' }}
                                >
                                    Описание неисправности:
                                </Typography>
                                <Field
                                    as={TextField}
                                    size="small"
                                    multiline
                                    name="malfunction_description"
                                    value={values.malfunction_description}
                                    onChange={handleChange}
                                    sx={{ flexGrow: 1 }}
                                />
                            </Box>
                            {/*works part*/}

                                <FieldArray name="work_items">
                                    {({ remove, push }) => (
                                        <Box sx={{ border: '1px solid #c4c4c4', p: 2, mt: 1,}}>
                                            {/* Main Labels */}
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, }}>
                                                <Typography variant="h6" fontWeight={'bold'}>
                                                    Наименование работ:
                                                </Typography>
                                                <Typography variant="h6" fontWeight={'bold'}>
                                                    ТМЗ:
                                                </Typography>
                                                <Typography variant="h6" fontWeight={'bold'}>
                                                    Комментарии проведенных работ:
                                                </Typography>
                                            </Box>
                                            {/* Render each work item */}
                                            {values.work_items?.map((workItem, index) => (
                                                <Box key={index} sx={{ display: 'flex', gap: 2, mt: 1, alignItems: 'center' }}>
                                                    {/* Number */}
                                                    <Typography variant="body1" sx={{ width: '30px', textAlign: 'center' }}>
                                                        {index + 1}.
                                                    </Typography>
                                                    {/* Work Name */}
                                                    <Box sx={{ flex: 1 }}>
                                                        <TextField
                                                            size={'small'}
                                                            fullWidth
                                                            multiline
                                                            placeholder="Выбор работы (как пример: замена масла)"
                                                            value={workItem.name}
                                                            onChange={(e) => setFieldValue(`work_items[${index}].name`, e.target.value)}
                                                        />
                                                    </Box>

                                                    {/* TMT Buttons */}
                                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                                        <Button variant="contained" color="primary"
                                                                onClick={() => {handleOpenModal(values, 'defect', index)}}
                                                        >
                                                            Дефектная ведомость
                                                        </Button>
                                                        <Button variant="contained" color="primary"
                                                                onClick={() => {handleOpenModal(values, 'install', index)}}
                                                        >
                                                            Установочный Акт
                                                        </Button>
                                                    </Box>
                                                    {/* Comment Field */}
                                                    <Box sx={{ flex: 1 }}>
                                                        <TextField
                                                            size={'small'}
                                                            fullWidth
                                                            multiline
                                                            placeholder="Комментарий проведенных работ"
                                                            value={workItem.comments}
                                                            onChange={(e) => setFieldValue(`work_items[${index}].comments`, e.target.value)}
                                                        />
                                                    </Box>

                                                    {/* Remove Button */}
                                                    {values.work_items.length > 0 && (
                                                        <IconButton
                                                            onClick={() => remove(index)}
                                                        >
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    )}
                                                    <Checkbox
                                                        checked={workItem.status === 'Завершён'}
                                                        disabled={!workItem.is_approved}
                                                        onChange={(e) => setFieldValue(`work_items[${index}].status`, e.target.checked ? 'Завершён' : 'Не завершён')}
                                                    />
                                                </Box>
                                            ))}

                                            {/* Add Work Button */}
                                            <Button
                                                onClick={() => push({ name: '', comments: '', status: 'Не завершён' })}
                                                sx={{ mt: 2 }}
                                                startIcon={<AddIcon />}
                                                variant="text"
                                                color="primary"
                                            >
                                                Добавить работу
                                            </Button>
                                        </Box>
                                    )}
                                </FieldArray>
                            {/*next part*/}
                            <Box sx={{border: '1px solid #c4c4c4', p: 2, mt: 1}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                <Typography variant="h6" fontWeight={'bold'}>
                                    Заказ открыл:
                                </Typography>
                                <Field
                                    as={TextField}
                                    size={'small'}
                                    name="card_opened_by"
                                    value={values.card_opened_by}
                                    onChange={handleChange}
                                    sx={{p: 2}}
                                />
                                </div>
                                <div>
                                    <Typography variant="h6" fontWeight={'bold'}>
                                        Произвели работу:
                                    </Typography>
                                    <FieldArray name="involved_workers">
                                        {({ push, remove }) => (
                                            <div>
                                                {values.involved_workers?.map((worker, index) => (
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            p: 1,
                                                            display: 'flex',
                                                            gap: '16px',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <Typography variant="h6" fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
                                                            Должность:
                                                        </Typography>
                                                        <Field
                                                            size={'small'}
                                                            as={TextField}
                                                            name={`involved_workers[${index}].position`}
                                                            value={worker.position}
                                                            onChange={handleChange}
                                                            sx={{ flexGrow: 1 }}
                                                        />
                                                        <Typography variant="h6" fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
                                                            ФИО:
                                                        </Typography>
                                                        <Field
                                                            as={TextField}
                                                            size={'small'}
                                                            name={`involved_workers[${index}].full_name`}
                                                            value={worker.full_name}
                                                            onChange={handleChange}
                                                            sx={{ flexGrow: 1 }}
                                                        />
                                                        {values.involved_workers.length > 1 && (
                                                            <IconButton
                                                                onClick={() => remove(index)}
                                                            >
                                                                <DeleteIcon/>
                                                            </IconButton>
                                                        )}
                                                    </Box>
                                                ))}
                                                <Button
                                                    onClick={() => push({ position: "", full_name: "" })}
                                                    startIcon={<AddIcon />}
                                                    sx={{ mt: 2 }}
                                                >
                                                    Добавить производителя работ
                                                </Button>
                                            </div>
                                        )}
                                    </FieldArray>
                                </div>
                            </Box>
                            {/*next part*/}
                            <Box sx={{border: '1px solid #c4c4c4', p: 2, mt: 1}}>
                                <div>
                                    <Typography variant="h6" fontWeight={'bold'}>
                                        Комиссия в составе:
                                    </Typography>
                                    <FieldArray name="commission_members">
                                        {({ push, remove }) => (
                                            <div>
                                                {values.commission_members?.map((comm, index) => (
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            p: 1,
                                                            display: 'flex',
                                                            gap: '16px',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <Typography variant="h6" fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
                                                            Должность:
                                                        </Typography>
                                                        <Field
                                                            size={'small'}
                                                            as={TextField}
                                                            name={`commission_members[${index}].position`}
                                                            value={comm.position}
                                                            onChange={handleChange}
                                                            sx={{ flexGrow: 1 }}
                                                        />
                                                        <Typography variant="h6" fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
                                                            ФИО:
                                                        </Typography>
                                                        <Field
                                                            as={TextField}
                                                            size={'small'}
                                                            name={`commission_members[${index}].full_name`}
                                                            value={comm.full_name}
                                                            onChange={handleChange}
                                                            sx={{ flexGrow: 1 }}
                                                        />
                                                        {values.commission_members.length > 1 && (
                                                            <IconButton
                                                                onClick={() => remove(index)}
                                                            >
                                                                <DeleteIcon/>
                                                            </IconButton>
                                                        )}
                                                    </Box>
                                                ))}
                                                <Button
                                                    onClick={() => push({ position: "", full_name: "" })}
                                                    startIcon={<AddIcon />}
                                                    sx={{ mt: 2 }}
                                                >
                                                    Добавить члена комиссии
                                                </Button>
                                            </div>
                                        )}
                                    </FieldArray>
                                    <Typography variant="h6" fontWeight={'bold'}>Заключение комиссии: </Typography>
                                    <Field
                                        as={TextField}
                                        size={'small'}
                                        fullWidth
                                        name="commission_conclusion"
                                        value={values.commission_conclusion}
                                        onChange={handleChange}
                                        sx={{p: 2}}
                                    />
                                </div>
                            </Box>
                            {/* Submit Button */}
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 1 }}>
                                <Button variant="contained" color="primary" type="submit">
                                    Сохранить
                                </Button>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', p: 1, gap: 2}}>
                                <Button onClick={() => handleReinstatement(values)} variant="contained" color="primary" size={'large'}>
                                    Вернуть в строй
                                </Button>
                                <Button onClick={()=> openModal('confirm')} variant="contained" color="primary" size={'large'}>
                                    Закрыть наряд
                                </Button>
                            </Box>
                        </Form>
                            {isOpen['install'] &&
                        <InstallationActModal open={true}
                                              onClose={() => closeModal('install')}
                                              commission={values.commission_members}
                                              marka={vehicle?.marka as string}
                                              mileage={vehicle?.default_tracking_type === 'Пробег' ? String(vehicle?.mileage) : String(vehicle?.moto_hours)}
                                              gos_number={vehicle?.gos_number as string}
                                              installForm={installItems}
                                              model={vehicle?.model as string}
                        />}
                            {isOpen['defect'] &&
                        <DefectActModal open={true}
                                        onClose={() => closeModal('defect')}
                                        gos_number={vehicle?.gos_number as string}
                                        mileage={vehicle?.default_tracking_type === 'Пробег' ? String(vehicle?.mileage) : String(vehicle?.moto_hours)}
                                        marka={vehicle?.marka as string}
                                        defectForm={defectForm}
                        />
                            }
                            <ClosingMaintenanceConfirmationModal
                                open={isOpen['confirm']}
                                onClose={() => closeModal('confirm')}
                                onConfirm={() => handleFinalSubmit(values)}
                                text={'закрыть наряд'}
                            />
                        </>
                    )}
                </Formik>
                <Button onClick={handleDownload} variant="contained" color="primary" size={'large'} sx={{mb:1}}>Скачать документ</Button>
            </Container>
        </>
    );
}

export default VehicleRepairForm;
