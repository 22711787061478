import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Box, FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {IVehicleRequest, IVehicleType } from '../../../../api/vehiclesApi';
import { useAppContext } from '../../../store/AppContext';
import { validationSchema } from './ValidationSchemaForForm';

const FormStep1: React.FC<{ handleNext: () => void }> = ({ handleNext }) => {
    const navigate = useNavigate();
    const { vehicleTypes, handleGetVehicleTypes, formData, setFormData } = useAppContext();

    useEffect(() => {
        handleGetVehicleTypes();
    }, []);

    const formik = useFormik({
        initialValues: {
            marka: formData.marka || '',
            model: formData.model || '',
            vehicle_type_id: formData.vehicle_type_id || '',
            color: formData.color || '',
            year: formData.year || '',
            license_category: formData.license_category || '',
            gos_number: formData.gos_number || '',
            serial_number: formData.serial_number || '',
            tech_passport_number: formData.tech_passport_number || '',
            garage_number: formData.garage_number || '',
            num_of_seats: formData.num_of_seats || '',
            mass_unloaded: formData.mass_unloaded || '',
            lifting_capacity: formData.lifting_capacity || '',
            tank_volume: formData.tank_volume || '',
            axis_number: formData.axis_number || '',
            height: formData.height || '',
            length: formData.length || '',
            width: formData.width || '',
            wheel_size: formData.wheel_size || '',
            mileage: formData.mileage || '',
            moto_hours: formData.moto_hours || '',
            condition: formData.condition || '',
            condition_comments: formData.condition_comments || '',
            type_special_fields: {},
            doc_front_image: formData.doc_front_image || '',
            doc_registration: formData.doc_registration || '',
            doc_tech_details: formData.doc_tech_details || '',
            additionalInfo: formData.additionalInfo || '',
            vehicle_type: formData.vehicle_type || ''
        },
        validationSchema, enableReinitialize: true,
        onSubmit: (values: IVehicleRequest) => {
            setFormData({ ...formData, ...values });
            void handleNext();
        },
    });

    useEffect(() => {
        if (formik.values.vehicle_type && vehicleTypes) {
            const matchedType = vehicleTypes.find(
                type => type.name === formik.values.vehicle_type
            );
            if (matchedType) {
                formik.setFieldValue('vehicle_type_id', matchedType.id);
            }
        }
    }, [formik.values.vehicle_type, vehicleTypes]);

    return (
        <Box
            component="form"
            onSubmit={formik.handleSubmit}
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="space-around"
        >
            <Box width="48%">
                <TextField
                    size={'small'}
                    label="Гаражный номер"
                    name="garage_number"
                    value={formik.values.garage_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.garage_number && Boolean(formik.errors.garage_number)}
                    helperText={formik.touched.garage_number && formik.errors.garage_number}
                    fullWidth
                    margin="normal"
                />
                <FormControl fullWidth margin="normal" size={'small'}>
                    <InputLabel id="vehicle-type-label">Тип</InputLabel>
                    <Select
                        labelId="vehicle-type-label"
                        id="vehicle_type_id"
                        name="vehicle_type_id"
                        value={formik.values.vehicle_type_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label="Тип"
                        error={formik.touched.vehicle_type_id && Boolean(formik.errors.vehicle_type_id)}
                    >
                        {vehicleTypes && vehicleTypes.map((type: IVehicleType) => (
                            <MenuItem key={type.id} value={type.id}>
                                {type.name}
                            </MenuItem>
                        ))}
                    </Select>
                    {formik.touched.vehicle_type_id && formik.errors.vehicle_type_id && (
                        <Box color="error.main" mt={0.5}>{formik.errors.vehicle_type_id}</Box>
                    )}
                </FormControl>
                <TextField
                    size={'small'}
                    label="Марка ТС"
                    name="marka"
                    value={formik.values.marka}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.marka && Boolean(formik.errors.marka)}
                    helperText={formik.touched.marka && formik.errors.marka}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    size={'small'}
                    label="Модель"
                    name="model"
                    value={formik.values.model}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.model && Boolean(formik.errors.model)}
                    helperText={formik.touched.model && formik.errors.model}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    size={'small'}
                    label="Регистрационный номер"
                    name="gos_number"
                    value={formik.values.gos_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.gos_number && Boolean(formik.errors.gos_number)}
                    helperText={formik.touched.gos_number && formik.errors.gos_number}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    size={'small'}
                    label="Цвет"
                    name="color"
                    value={formik.values.color}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.color && Boolean(formik.errors.color)}
                    helperText={formik.touched.color && formik.errors.color}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    size={'small'}
                    label="Номер техпаспорта"
                    name="tech_passport_number"
                    value={formik.values.tech_passport_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.tech_passport_number && Boolean(formik.errors.tech_passport_number)}
                    helperText={formik.touched.tech_passport_number && formik.errors.tech_passport_number}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    size={'small'}
                    label="Год"
                    name="year"
                    type="number"
                    value={formik.values.year}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.year && Boolean(formik.errors.year)}
                    helperText={formik.touched.year && formik.errors.year}
                    fullWidth
                    margin="normal"
                />
                <FormControl fullWidth margin="normal" size={'small'}>
                    <InputLabel id="license-category-label">Категория управления</InputLabel>
                    <Select
                        labelId="license-category-label"
                        id="license_category"
                        name="license_category"
                        value={formik.values.license_category}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label="Категория управления"
                        error={formik.touched.license_category && Boolean(formik.errors.license_category)}
                    >
                        {['A', 'B', 'C', 'D', 'E'].map((category) => (
                            <MenuItem key={category} value={category}>
                                {category}
                            </MenuItem>
                        ))}
                    </Select>
                    {formik.touched.license_category && formik.errors.license_category && (
                        <Box color="error.main" mt={0.5}>{formik.errors.license_category}</Box>
                    )}
                </FormControl>
                <TextField
                    size={'small'}
                    label="Грузоподъемность, кг"
                    name="lifting_capacity"
                    type="number"
                    value={formik.values.lifting_capacity}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.lifting_capacity && Boolean(formik.errors.lifting_capacity)}
                    helperText={formik.touched.lifting_capacity && formik.errors.lifting_capacity}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    size={'small'}
                    label="Объем топливного бака"
                    name="tank_volume"
                    type="number"
                    value={formik.values.tank_volume}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.tank_volume && Boolean(formik.errors.tank_volume)}
                    helperText={formik.touched.tank_volume && formik.errors.tank_volume}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    size={'small'}
                    label="Кол-во посадочных мест"
                    name="num_of_seats"
                    type="number"
                    value={formik.values.num_of_seats}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.num_of_seats && Boolean(formik.errors.num_of_seats)}
                    helperText={formik.touched.num_of_seats && formik.errors.num_of_seats}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    size={'small'}
                    label="Вес без груза, кг"
                    name="mass_unloaded"
                    type="number"
                    value={formik.values.mass_unloaded}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.mass_unloaded && Boolean(formik.errors.mass_unloaded)}
                    helperText={formik.touched.mass_unloaded && formik.errors.mass_unloaded}
                    fullWidth
                    margin="normal"
                />
            </Box>
            <Box width="48%">
                <TextField
                    size={'small'}
                    label="Количество осей"
                    name="axis_number"
                    type="number"
                    value={formik.values.axis_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.axis_number && Boolean(formik.errors.axis_number)}
                    helperText={formik.touched.axis_number && formik.errors.axis_number}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    size={'small'}
                    label="Высота, мм"
                    name="height"
                    type="number"
                    value={formik.values.height}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.height && Boolean(formik.errors.height)}
                    helperText={formik.touched.height && formik.errors.height}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    size={'small'}
                    label="Длина, мм"
                    name="length"
                    type="number"
                    value={formik.values.length}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.length && Boolean(formik.errors.length)}
                    helperText={formik.touched.length && formik.errors.length}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    size={'small'}
                    label="Ширина, мм"
                    name="width"
                    type="number"
                    value={formik.values.width}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.width && Boolean(formik.errors.width)}
                    helperText={formik.touched.width && formik.errors.width}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    size={'small'}
                    label="Серийный номер(VIN)"
                    name="serial_number"
                    value={formik.values.serial_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.serial_number && Boolean(formik.errors.serial_number)}
                    helperText={formik.touched.serial_number && formik.errors.serial_number}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    size={'small'}
                    label="Размер колеса"
                    name="wheel_size"
                    type="number"
                    value={formik.values.wheel_size}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.wheel_size && Boolean(formik.errors.wheel_size)}
                    helperText={formik.touched.wheel_size && formik.errors.wheel_size}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    size={'small'}
                    label="Пробег, км"
                    name="mileage"
                    type="number"
                    value={formik.values.mileage}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.mileage && Boolean(formik.errors.mileage)}
                    helperText={formik.touched.mileage && formik.errors.mileage}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    size={'small'}
                    label="Моточасы, м/ч"
                    name="moto_hours"
                    type="number"
                    value={formik.values.moto_hours}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.moto_hours && Boolean(formik.errors.moto_hours)}
                    helperText={formik.touched.moto_hours && formik.errors.moto_hours}
                    fullWidth
                    margin="normal"
                />
                <FormControl fullWidth margin="normal" size={'small'}>
                    <InputLabel id="condition-label">Статус ТС</InputLabel>
                    <Select
                        labelId="condition-label"
                        id="condition"
                        name="condition"
                        value={formik.values.condition}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label="Статус ТС"
                        error={formik.touched.condition && Boolean(formik.errors.condition)}
                    >
                        {['В работе', 'На ремонте', 'Требует ремонта', 'В ожидании ремонта', 'На консервации'].map((condition) => (
                            <MenuItem key={condition} value={condition}>
                                {condition}
                            </MenuItem>
                        ))}
                    </Select>
                    {formik.touched.condition && formik.errors.condition && (
                        <Box color="error.main" mt={0.5}>{formik.errors.condition}</Box>
                    )}
                </FormControl>
                <TextField
                    size={'small'}
                    label="Комментарии по состоянию ТС"
                    name="condition_comments"
                    value={formik.values.condition_comments}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.condition_comments && Boolean(formik.errors.condition_comments)}
                    helperText={formik.touched.condition_comments && formik.errors.condition_comments}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    size={'small'}
                    label="Доп. инфо"
                    name="additionalInfo"
                    value={formik.values.additionalInfo}
                    onChange={formik.handleChange}
                    fullWidth
                    margin="normal"
                />
            </Box>
            <Box mt={2} display="flex" justifyContent="space-between" width="100%" padding={'0 16px 0 16px'}>
                <Button onClick={() => navigate('/vehicles')} color="primary" variant="contained">
                    Отмена
                </Button>
                <Button color="primary" variant="contained" type="submit">
                    Далее
                </Button>
            </Box>
        </Box>
    );
};

export default FormStep1;
