import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Autocomplete,
    Select,
    MenuItem, SelectChangeEvent, InputLabel, FormControl, FormControlLabel, Checkbox
} from '@mui/material';
import { IVehicleType } from "../../../api/vehiclesApi";
import { IInventoryArrival, IInventoryOverview } from "../../../api/inventoryApi";
import * as yup from 'yup';

interface InventoryArrivalModalProps {
    open: boolean;
    onClose: () => void;
    vehicleNames: string[];
    inventoryOptions: IInventoryOverview[];
    onSave: (arrival: IInventoryArrival) => void;
}

const InventoryArrivalModal: React.FC<InventoryArrivalModalProps> = ({ open, onClose, vehicleNames, inventoryOptions, onSave }) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [isCommonItem, setIsCommonItem] = useState<boolean>(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [formData, setFormData] = useState<IInventoryArrival>({
        id: 0,
        name: '',
        code: '',
        quantity: 0,
        unit: '',
        price: 0,
        vehicle_names: [],
        note: null,
        provider: '',
        is_common_item: false
    });

    const [isReadOnly, setIsReadOnly] = useState(false);

    useEffect(() => {
        if (!open) {
            // Reset the form when the modal is closed
            setFormData({
                id: 0,
                name: '',
                code: '',
                quantity: 0,
                unit: '',
                price: 0,
                vehicle_names: [],
                note: null,
                provider: '',
                is_common_item: false
            });
            setInputValue(''); // Clear input value
            setIsReadOnly(false);
        }
    }, [open]);

    useEffect(() => {
        // Only update the name in formData if it's a freeSolo (not selected from the list)
        if (formData.id === 0) {
            setFormData((prevData) => ({
                ...prevData,
                name: inputValue,
            }));
        }
    }, [inputValue]);

    const handleAutocompleteChange = (event: React.SyntheticEvent, value: IInventoryOverview | string | null) => {
        if (value && typeof value === 'object') {

            setFormData({
                id: value.id,
                name: value.name,
                code: value.code,
                quantity: 0, // Always keep quantity editable
                unit: value.unit,
                price: value.price,
                vehicle_names: value.vehicles.map(v => v),
                note: '', // Always keep note editable
                provider: '',
                is_common_item: false
            });
            setIsReadOnly(true);
            setInputValue(value.name); // Set input value to the selected item name
        } else if (value === null || value === '') {
            // If cleared or a custom value is typed, reset the form
            setFormData({
                id: 0,
                name: '',
                code: '',
                quantity: 0,
                unit: '',
                price: 0,
                vehicle_names: [], // Reset to an empty array
                note: null,
                provider: '',
                is_common_item: false
            });
            setIsReadOnly(false);
            setInputValue('');
        } else {
            // If a custom value is typed
            setInputValue(value as string);
            setIsReadOnly(false);
        }
    };

    const schema = yup.object().shape({
        code: yup.string().required('Обязательное поле'),
        name: yup.string().required('Обязательное поле'),
        quantity: yup.number().min(1, 'Кол-во должно быть больше 0').required('Обязательное поле'),
        unit: yup.string().required('Обязательное поле'),
        price: yup.number().min(1, 'Цена за ед. должно быть больше 0').required('Обязательное поле'),
        is_common_item: yup.boolean().default(false),
        vehicle_names: yup.array().when('is_common_item', (is_common_item, schema) => {
            return is_common_item ? schema.notRequired() : schema.min(1, 'Обязательное поле');
        }),
        provider: yup.string().required('Обязательное поле'),
        note: yup.string().nullable(),
    });


    const handleInputChange = (field: keyof IInventoryArrival) => (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
    ) => {
        const value = event.target.value;
        setFormData({ ...formData, [field]: value });
        setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: '',
        }));
    };

    const handleSave = async () => {
        try {
            await schema.validate(formData, { abortEarly: false });
            setErrors({}); // Clear errors if validation succeeds
            onSave(formData);
            onClose();
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                const validationErrors: { [key: string]: string } = {};
                error.inner.forEach((err) => {
                    if (err.path) {
                        validationErrors[err.path] = err.message;
                    }
                });
                setErrors(validationErrors);
            }
        }
    };
    useEffect(() => {
        if (open) {
            setErrors({});
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Приход</DialogTitle>
            <DialogContent>
                <TextField
                    label="Код"
                    value={formData.code}
                    onChange={handleInputChange('code')}
                    fullWidth
                    required
                    error={!!errors.code}
                    helperText={errors.code}
                    margin="normal"
                    InputProps={{ readOnly: isReadOnly }}
                />
                <Autocomplete
                    options={inventoryOptions || []}
                    getOptionLabel={(option) => typeof option === 'string' ? option : option?.name}
                    freeSolo
                    inputValue={inputValue} // Control the input value
                    onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                    onChange={handleAutocompleteChange}
                    renderInput={(params) =>
                        <TextField {...params}
                                   required
                                   label="Наименование"
                                   error={!!errors.name}
                                   helperText={errors.name} />}
                />
                <TextField
                    label="Кол-во"
                    value={formData.quantity}
                    onChange={handleInputChange('quantity')}
                    inputProps={{ min: 1 }}
                    type="number"
                    error={!!errors.quantity}
                    helperText={errors.quantity}
                    fullWidth
                    required
                    margin="normal"
                />
                <FormControl fullWidth margin="normal" disabled={isReadOnly}>
                    <InputLabel id="unit-label">Ед.изм</InputLabel>
                    <Select
                        labelId="unit-label"
                        value={formData.unit}
                        required
                        onChange={handleInputChange('unit')}
                        inputProps={{ readOnly: isReadOnly }}
                        label="Ед.изм"
                    >
                        <MenuItem value="шт">шт.</MenuItem>
                        <MenuItem value="л">л.</MenuItem>
                        <MenuItem value="компл">компл.</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label="Цена за ед."
                    value={formData.price}
                    onChange={handleInputChange('price')}
                    type="number"
                    error={!!errors.price}
                    helperText={errors.price}
                    fullWidth
                    required
                    margin="normal"
                    InputProps={{ readOnly: isReadOnly }}
                />
                <Autocomplete
                    multiple
                    options={vehicleNames}
                    getOptionLabel={(option) => option}
                    onChange={(event, value) => {
                        setFormData({ ...formData, vehicle_names: value });
                    }}
                    value={formData.vehicle_names || []}
                    renderInput={(params) => <TextField {...params} required={!isCommonItem}   error={!!errors.vehicle_names}
                                                        helperText={errors.vehicle_names} label="Назначение" />}
                    disabled={isReadOnly || isCommonItem}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isCommonItem}
                            onChange={(event) => {
                                const checked = event.target.checked;
                                setIsCommonItem(checked);
                                setFormData({
                                    ...formData,
                                    vehicle_names: checked ? [] : formData.vehicle_names, // Clear vehicle_names if checked
                                    is_common_item: checked  // Set is_common_item to true if checked
                                });
                            }}
                        />
                    }
                    label="Выбор всех ТС"
                />
                <TextField
                    label="Поставщик"
                    value={formData.provider || ''}
                    onChange={handleInputChange('provider')}
                    fullWidth
                    error={!!errors.provider}
                    helperText={errors.provider}
                    required
                    margin="normal"
                />
                <TextField
                    label="Примечание"
                    value={formData.note || ''}
                    onChange={handleInputChange('note')}
                    fullWidth
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <Button onClick={handleSave} variant="contained" color="primary">
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InventoryArrivalModal;
