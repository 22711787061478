import React, { useEffect, useState } from "react";
import {
    Modal,
    Box,
    Typography,
    TextField,
    Button,
    MenuItem,
    Autocomplete, FormControl, InputLabel, Select, SelectChangeEvent,
} from "@mui/material";
import { IFuelGroup, FuelItem } from "./FuelAccounting";
import * as Yup from "yup";

interface FuelAddModalProps {
    open: boolean;
    onClose: () => void;
    groups: IFuelGroup[];
    vehicleNames: string[];
    selectedFuelItem: FuelItem | null; // Pass selectedFuelItem from parent
    onSubmit: (data: any) => void;
}

const FuelAddModal: React.FC<FuelAddModalProps> = ({
                                                       open,
                                                       onClose,
                                                       groups,
                                                       vehicleNames,
                                                       selectedFuelItem,
                                                       onSubmit,
                                                   }) => {
    const [formData, setFormData] = useState({
        group_id: "",
        name: "",
        quantity: "",
        unit: "",
        price: "",
        provider: "",
        note: "",
        vehicle_names: [] as string[],
        type: "gsm",
    });
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    // Populate form when selectedFuelItem changes
    useEffect(() => {
        if (selectedFuelItem) {
            setFormData({
                group_id: selectedFuelItem.group_id.toString(),
                name: selectedFuelItem.name,
                quantity: "",
                unit: selectedFuelItem.unit,
                price: selectedFuelItem.price.toString(),
                provider: "",
                note: "",
                vehicle_names: selectedFuelItem.vehicle_names || [],
                type: selectedFuelItem.type,
            });
        } else {
            resetForm();
        }
    }, [selectedFuelItem]);

    const resetForm = () => {
        setFormData({
            group_id: "",
            name: "",
            quantity: "",
            unit: "",
            price: "",
            provider: "",
            note: "",
            vehicle_names: [],
            type: "gsm",
        });
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
    ) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFormSubmit = async () => {
        try {
            await validationSchema.validate(formData, { abortEarly: false });

            const { quantity, provider, note, group_id } = formData;
            const submissionData: Record<string, any> = {};

            if (selectedFuelItem) {
                // For existing fuel, include the ID and only changed fields
                submissionData.id = selectedFuelItem.id;

                if (quantity) submissionData.quantity = quantity;
                if (provider) submissionData.provider = provider;
                if (group_id) submissionData.group_id = group_id;
                if (note) submissionData.note = note;
            } else {
                // For new fuel, include all fields
                submissionData.group_id = formData.group_id;
                submissionData.name = formData.name;
                submissionData.quantity = quantity;
                submissionData.unit = formData.unit;
                submissionData.price = formData.price;
                submissionData.provider = provider;
                submissionData.note = note;
                submissionData.type = formData.type;
                submissionData.vehicle_names = formData.vehicle_names;
            }

            console.log("Submission Data:", submissionData); // Debugging
            onSubmit(submissionData);
            onClose();
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                // Collect validation errors
                const newErrors = err.inner.reduce((acc, error) => {
                    if (error.path) {
                        acc[error.path] = error.message;
                    }
                    return acc;
                }, {} as Record<string, string>);

                console.log("Validation Errors:", newErrors);
                setErrors(newErrors);
            }
        }
    };

    const validationSchema = Yup.object().shape({
        group_id: Yup.string().required("Группа обязательна"),
        name: Yup.string().required("Название обязательно"),
        quantity: Yup.number()
            .typeError("Количество должно быть числом")
            .positive("Количество должно быть больше нуля")
            .required("Количество обязательно"),
        unit: Yup.string().required("Единица измерения обязательна"),
        price: Yup.number()
            .typeError("Цена должна быть числом")
            .positive("Цена должна быть больше нуля")
            .required("Цена обязательна"),
        provider: Yup.string().required("Поставщик обязателен"),
    });

    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
                resetForm();
                setErrors({});
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    width: '500px',
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Добавить
                </Typography>
                <TextField
                    fullWidth
                    select
                    label="Группа"
                    name="group_id"
                    value={formData.group_id}
                    required
                    onChange={handleInputChange}
                    margin="normal"
                    helperText={errors.group_id}
                    error={!!errors.group_id}
                    disabled={!!selectedFuelItem} // Disable for existing fuel
                >
                    {groups?.map((group) => (
                        <MenuItem key={group.id} value={group.id}>
                            {group.name}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    fullWidth
                    label="Название"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    error={!!errors.name}
                    helperText={errors.name}
                    required
                    margin="normal"
                    disabled={!!selectedFuelItem} // Disable for existing fuel
                />
                <TextField
                    fullWidth
                    label="Количество"
                    name="quantity"
                    type="number"
                    value={formData.quantity}
                    error={!!errors.quantity}
                    helperText={errors.quantity}
                    required
                    onChange={handleInputChange}
                    margin="normal"
                />
                <FormControl fullWidth margin="normal"  required>
                    <InputLabel id="unit-label">Ед. изм.</InputLabel>
                    <Select
                        labelId="unit-label"
                        value={formData.unit}
                        name="unit"
                        onChange={handleInputChange}
                    >
                        <MenuItem value="шт">шт.</MenuItem>
                        <MenuItem value="л">л.</MenuItem>
                        <MenuItem value="компл">компл.</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    fullWidth
                    label="Цена"
                    name="price"
                    type="number"
                    error={!!errors.price}
                    required
                    helperText={errors.price}
                    value={formData.price}
                    onChange={handleInputChange}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Поставщик"
                    name="provider"
                    value={formData.provider}
                    helperText={errors.provider}
                    error={!!errors.provider}
                    required
                    onChange={handleInputChange}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Примечание"
                    name="note"
                    value={formData.note}
                    onChange={handleInputChange}
                    margin="normal"
                />
                <Autocomplete
                    multiple
                    options={vehicleNames}
                    value={formData.vehicle_names}
                    onChange={(event, newValue) =>
                        setFormData((prev) => ({ ...prev, vehicle_names: newValue }))
                    }
                    renderInput={(params) => (
                        <TextField {...params} label="Транспортные средства" margin="normal" />
                    )}
                    fullWidth
                    disabled={!!selectedFuelItem} // Disable for existing fuel
                />
                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                    <Button
                        onClick={() => {
                            onClose();
                            resetForm();
                            setErrors({});
                        }}
                        sx={{ mr: 1 }}
                    >
                        Отмена
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFormSubmit}
                    >
                        Добавить
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default FuelAddModal;
