import React from 'react';
import { Box, Typography, SxProps, Theme } from '@mui/material';

interface StatusBarProps {
    currentMileage: number;
    lastMaintenanceMileage: number | null; // Allow null
    interval: number;
    trackingType: string;
    text: string;
    initialMileage: number; // Fallback value
}

const statusBarStyles = (percentage: number, exceeded: boolean): SxProps<Theme> => ({
    height: '20px',
    width: '100%',
    borderRadius: '5px',
    backgroundColor: exceeded ? 'brown' : (percentage < 80 ? (percentage < 50 ? 'green' : 'yellow') : 'orange'),
    position: 'relative',
});

const separatorStyle = (percentage: number, exceeded: boolean): SxProps<Theme> => ({
    position: 'absolute',
    left: exceeded ? '100%' : `${percentage}%`,
    top: '0',
    bottom: '0',
    borderLeft: '2px solid black',
    transform: 'translateX(-50%)',
});

const StatusBar: React.FC<StatusBarProps> = ({ currentMileage, lastMaintenanceMileage, interval, trackingType, text, initialMileage }) => {
    // Use initialMileage if lastMaintenanceMileage is null
    const effectiveLastMileage = lastMaintenanceMileage !== null ? lastMaintenanceMileage : initialMileage;
    const maxMileage = effectiveLastMileage + interval;
    const exceeded = currentMileage > maxMileage;
    const percentage = exceeded ? 100 : Math.max(0, Math.min(100, ((currentMileage - effectiveLastMileage) / interval) * 100));
    const excessMileage = currentMileage - maxMileage;

    return (
        <Box display="flex" alignItems="center" mb={6} alignSelf={'center'} position={'relative'}>
            <Typography variant="body2" sx={{ position: 'absolute', bottom: 18 }}>{text}</Typography>
            <Typography variant="body2">0</Typography>
            <Box sx={{ width: '250px', border: '1px solid #ddd', margin: '0 10px', position: 'relative', borderRadius: '5px', height: '20px' }}>
                <Box sx={statusBarStyles(percentage, exceeded)}>
                    <Box sx={separatorStyle(percentage, exceeded)} />
                    {exceeded && (
                        <Box sx={{ position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)' }}>
                            <Typography variant="body2" sx={{ color: 'white', textWrap: 'nowrap' }}>
                                перепробег {excessMileage} {trackingType === "Пробег" ? "км" : "м/ч"}
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Box sx={{ position: 'absolute', top: '25px', left: `${percentage}%`, transform: 'translateX(-50%)' }}>
                    <Typography variant="body2">{currentMileage} {trackingType === "Пробег" ? "км" : "м/ч"}</Typography>
                </Box>
            </Box>
            <Typography variant="body2">{interval}</Typography>
        </Box>
    );
};

export default StatusBar;
