import React, { useEffect, useState } from 'react';
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    SxProps,
    Theme,
    TextField,
    Button,
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    SelectChangeEvent,
    IconButton,
    CircularProgress,
} from '@mui/material';
import {IPostDate, MaintenanceApi} from "../../api/maintenanceApi";
import CommonContainer from '../components/CommonContainer/CommonContainer';
import ModalMaintenance from "./modal/ModalMaintenance";
import { zebraStyle } from "../VehiclesPage/VehiclesMain";
import StatusIndicator from "./components/StatusIndicator";
import dayjs from "dayjs";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import 'dayjs/locale/ru';
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useFileDownload} from "../../hooks/useFileDownload";
import DateRangePickerModal from "./components/DateRangePickerModal";
dayjs.locale('ru');

export interface IMaintenance {
    id: number;
    vehicle_id: number;
    vehicle_name: string;
    vehicle_plate: string;
    date: string | null;
    vehicle_tracking_record_at_last_maintenance_to1: number;
    vehicle_tracking_record_at_last_maintenance_to2: number;
    vehicle_current_tracking_record: number;
    default_tracking_interval_for_maintenance_to1: number;
    default_tracking_interval_for_maintenance_to2: number;
    maintenance_history: IPostDate[];
    default_tracking_type: string;
    vehicle_initial_tracking_record: number;
}
export interface IMaintenanceStatus {
    hasPlanned : boolean;
    hasDraft: boolean;
    hasCompleted: boolean;
}

const calendarCellStyle: SxProps<Theme> = {
    width: '25px',
    height: '25px',
    textAlign: 'center',
    cursor: 'pointer',
    border: '1px solid #ddd',
    borderRadius: '5px',
    margin: '2px',
    display: 'inline-block',
    '&:hover': {
        backgroundColor: '#f0f0f0',
    },
};

const circleStyle: SxProps<Theme> = {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '8px',
};

const plannedStyle: SxProps<Theme> = {
    backgroundColor: 'yellow',
};

const actualStyle: SxProps<Theme> = {
    backgroundColor: 'green',
};

const overdueStyle: SxProps<Theme> = {
    backgroundColor: 'red',
};

const renderCalendarCells = (
    vehicle: IMaintenance,
    handleDateClick: (date: string, vehicle: IMaintenance, status: IMaintenanceStatus) => void,
) => {
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const totalDays = dayjs(new Date()).daysInMonth();
    const cells = [];

    for (let day = 1; day <= totalDays; day++) {
        let cellSx: SxProps<Theme> = calendarCellStyle;

        // Check if this day has plan, draft, or completed statuses
        const hasPlanned = vehicle.maintenance_history.some(m => {
            const date = m.type === 'plan' ? m.date : null;
            return date ? dayjs(date).date() === day : false;
        });

        const hasDraft = vehicle.maintenance_history.some(m => {
            const date = m.type !== 'plan' && m.status === 'draft' ? m.date : null;
            return date ? dayjs(date).date() === day : false;
        });

        const hasCompleted = vehicle.maintenance_history.some(m => {
            const date = m.type !== 'plan' && m.status === 'completed' ? m.date : null;
            return date ? dayjs(date).date() === day : false;
        });

        // Apply diagonal style for combinations
        if (hasPlanned && hasCompleted) {
            cellSx = {
                ...cellSx,
                background: 'linear-gradient(45deg, yellow 50%, green 50%)',
            };
        } else if (hasPlanned && hasDraft) {
            cellSx = {
                ...cellSx,
                background: 'linear-gradient(45deg, yellow 50%, orange 50%)',
            };
        } else if (hasPlanned) {
            cellSx = { ...cellSx, backgroundColor: 'yellow' };
        } else if (hasDraft) {
            cellSx = { ...cellSx, backgroundColor: 'orange' };
        } else if (hasCompleted) {
            cellSx = { ...cellSx, backgroundColor: 'green' };
        }
        const formattedDate = dayjs(`${currentYear}-${currentMonth}-${day}`).format(`DD.MM.YYYY`);
        cells.push(
            <Box key={day} sx={cellSx} onClick={() => handleDateClick(formattedDate, vehicle, {hasCompleted, hasDraft, hasPlanned} )}>
                {day}
            </Box>
        );
    }
    return cells;
};

const MaintenancePage: React.FC = () => {
    const [maintenances, setMaintenances] = useState<IMaintenance[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState<string | null>(null);
    const [selectedVehicleId, setSelectedVehicleId] = useState<number | null>(null);
    const [selectedType, setSelectedType] = useState<'planned' | 'actual' | null>(null);
    const [selectedMaintenanceId, setSelectedMaintenanceId] = useState<string>('');
    const maintenanceApi = new MaintenanceApi();
    const navigate = useNavigate();
    const { downloadFile, loading, error } = useFileDownload();
    const [datePickerModalOpen, setDatePickerModalOpen] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    // const [selectedMonth, setSelectedMonth] = useState<string | null>(null);

    // const handleMonthChange = (date: dayjs.Dayjs | null) => {
    //     if (date) {
    //         setSelectedMonth(date.format('YYYY-MM'));
    //     } else {
    //         setSelectedMonth(null);
    //     }
    // };

    // const handleDownload = () => {
    //     if (selectedMonth) {
    //         console.log(`Selected month: ${selectedMonth}`);
    //     } else {
    //         console.log('No date selected');
    //     }
    // };

    const handleGetMaintenances = async () => {
        try {
            const response = await maintenanceApi.getMaintenances();
            setMaintenances(response);
        } catch (e) {
            console.log(e)
        }
    };

    useEffect(() => {
        void handleGetMaintenances();
    }, []);

    const handleDownloadFile = async (id: number) => {
        try {
            downloadFile({
                apiCall: () => maintenanceApi.downloadMaintenanceReport(id)
            });
        } catch (error) {
            console.error("Error downloading file: ", error);
        }
    };


    const handleDateClick = (date: string, vehicle: IMaintenance, status: IMaintenanceStatus) => {
        setSelectedDate(date);
        setSelectedVehicleId(vehicle.vehicle_id);
        const maintenanceId = vehicle.maintenance_history.find((m) =>
            dayjs(m.date).format('DD.MM.YYYY') === date && m.type !== 'plan'
        )?.id;

        setModalOpen(true);
        if(status.hasDraft && maintenanceId) {
            navigate(`/maintenance/report/${vehicle.vehicle_id}/${date}/${maintenanceId} `);
        }
        if(status.hasCompleted && maintenanceId) {
            setModalOpen(false)
            handleDownloadFile(maintenanceId)
        }
        setSelectedMaintenanceId(String(maintenanceId));
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedDate(null);
        setSelectedVehicleId(null);
        setSelectedType(null);
    };

    const handlePlannedDate = async (date: string, vehicleId: number) => {
        try {
            const [day, month, year] = date.split('.');
            const formattedDate = dayjs(`${year}-${month}-${day}`)
                .set('hour', 0)
                .set('minute', 0)
                .set('second', 0)
                .format('YYYY-MM-DDTHH:mm:ss');
            await maintenanceApi.postDate({ date: formattedDate, vehicle_id: vehicleId, type: 'plan'});
            toast.success('Плановое ТО успешно указано');
        } catch (e: any) {
            toast.error(e.response.data.msg);
        } finally { 
            await handleGetMaintenances();
            handleCloseModal();
        }
    };

    const handleActualDate = async (date: string, vehicleId: number, maintenanceId: string) => {
        navigate(`/maintenance/report/${vehicleId}/${encodeURIComponent(date)}${maintenanceId ? '/' + maintenanceId : ''} `);
    }

    const months = [
        'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
        'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
    ];
    const getCurrentYearMonths = () => {
        const currentYear = dayjs().year();
        return months.map((month, index) => ({
            label: `${month} ${currentYear}`,
            value: `${currentYear}-${String(index + 1).padStart(2, '0')}`
        }));
    };

    const [selectedMonths, setSelectedMonths] = useState<string[]>([]);

    const handleDownload = async () => {
        try {
            const blob = await maintenanceApi.getExcel(selectedMonths);
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'report.xlsx'); // or any other file name
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } catch (error) {
            console.error('Download failed', error);
        }
    };
    const handleChange = (event: SelectChangeEvent<string[]>) => {
        setSelectedMonths(event.target.value as string[]);
    };

    const handleOpenDatePickerModal = (vehicle_id: number) => {
        setSelectedVehicleId(vehicle_id);
        setDatePickerModalOpen(true);
    };

    const handleDownloadMaintenances = async (startDate: string, endDate: string) => {
        setIsDownloading(true);
        console.log('Download started, isDownloading:', isDownloading);
        try {
           await downloadFile({
                apiCall: () => maintenanceApi.downloadMaintenanceReports(selectedVehicleId as number, startDate, endDate)
            });
        } catch (e) {
            console.log(e);
        }
        finally {
            setSelectedVehicleId(null)
            setIsDownloading(false);
            console.log('Download finished, isDownloading:', isDownloading);
        }
    }

    return (
        <CommonContainer>
            <Typography variant="h4" gutterBottom>
                Планирование ТО
            </Typography>
            <Box display="flex" mb={2}>
                <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                    <FormControl sx={{ minWidth: 200 }}>
                        <Select
                            labelId="months-select-label"
                            multiple
                            size={'small'}
                            value={selectedMonths}
                            onChange={handleChange}
                            renderValue={(selected) => (selected as string[]).join(', ')}
                        >
                            {getCurrentYearMonths()?.map((month) => (
                                <MenuItem key={month.value} value={month.value}>
                                    <Checkbox checked={selectedMonths.indexOf(month.value) > -1} />
                                    <ListItemText primary={month.label} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button variant="contained" color="primary" onClick={handleDownload} disabled={!selectedMonths.length}>
                       Скачать отчет
                    </Button>
                </Box>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Наименование объекта</TableCell>
                            <TableCell>Гос. номер</TableCell>
                            <TableCell>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body2">Талоны ТО</Typography>
                                    {isDownloading && (
                                        <CircularProgress size={20} sx={{ marginLeft: 2 }} />
                                    )}
                                </Box>
                            </TableCell>
                            <TableCell>Статус ТО</TableCell>
                            <TableCell>Дата</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {maintenances?.map((maintenance, index) => (
                            <>
                            <TableRow sx={zebraStyle(index)} key={maintenance.id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>
                                    <Typography variant="body1">{maintenance.vehicle_name}</Typography>
                                    <Typography variant="caption" display="block" noWrap>предыдущее ТО1: {maintenance.vehicle_tracking_record_at_last_maintenance_to1}&nbsp;
                                        {maintenance.default_tracking_type === "Пробег" ? "км" : "м/ч"}
                                    </Typography>
                                    <Typography variant="caption" display="block">
                                        следующее ТО1:
                                        {(maintenance.vehicle_tracking_record_at_last_maintenance_to1 || maintenance.vehicle_initial_tracking_record) + maintenance.default_tracking_interval_for_maintenance_to1}&nbsp;
                                        {maintenance.default_tracking_type === "Пробег" ? "км" : "м/ч"}
                                    </Typography>
                                    <Typography variant="caption" display="block">
                                        следующее ТО2:
                                        {(maintenance.vehicle_tracking_record_at_last_maintenance_to2 || maintenance.vehicle_initial_tracking_record) + maintenance.default_tracking_interval_for_maintenance_to2}&nbsp;
                                        {maintenance.default_tracking_type === "Пробег" ? "км" : "м/ч"}
                                    </Typography>
                                </TableCell>
                                <TableCell>{maintenance.vehicle_plate}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleOpenDatePickerModal(maintenance.vehicle_id)}>
                                        <FileDownloadIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <Box display={'flex'} flexDirection={'column'} height={'auto'}>
                                    <StatusIndicator currentMileage={maintenance.vehicle_current_tracking_record}
                                                     interval={maintenance.default_tracking_interval_for_maintenance_to1}
                                                     lastMaintenanceMileage={maintenance.vehicle_tracking_record_at_last_maintenance_to1}
                                                     trackingType={maintenance.default_tracking_type}
                                                     text={'ТО1'}
                                                     initialMileage={maintenance.vehicle_initial_tracking_record}
                                    />
                                    <StatusIndicator currentMileage={maintenance.vehicle_current_tracking_record}
                                                     interval={maintenance.default_tracking_interval_for_maintenance_to2}
                                                     lastMaintenanceMileage={maintenance.vehicle_tracking_record_at_last_maintenance_to2}
                                                     trackingType={maintenance.default_tracking_type}
                                                     text={'ТО2'}
                                                     initialMileage={maintenance.vehicle_initial_tracking_record}
                                    />
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box mt={2}>
                                        <Typography variant="subtitle1">Техническое обслуживание</Typography>
                                        <Box display="flex" flexWrap="wrap">
                                            {renderCalendarCells(maintenance, handleDateClick)}
                                        </Box>
                                    </Box>
                                </TableCell>
                            </TableRow>
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {selectedDate && selectedVehicleId !== null && (
                <ModalMaintenance
                    open={modalOpen}
                    onClose={handleCloseModal}
                    handlePlanned={() => handlePlannedDate(selectedDate, selectedVehicleId)}
                    date={selectedDate}
                    handleActual={() => handleActualDate(selectedDate, selectedVehicleId, selectedMaintenanceId)}
                />
            )}
            <DateRangePickerModal
                open={datePickerModalOpen}
                onClose={() => setDatePickerModalOpen(false)}
                onDownload={handleDownloadMaintenances}
            />
        </CommonContainer>
    );
};

export default MaintenancePage;
